import img1 from "./img-1.png"
import img2 from "./img-2.png"
import img3 from "./img-3.png"
import img4 from "./img-4.png"
import img5 from "./img-5.png"
import img6 from "./img-6.png"

export default {
  img1,
  img4,
  img6,
  img5,
  img3,
  img2,
}

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  OrderStatus2,
  CouponCode,
  TagBy,
  SubscriptionPrice,
  Duration,
  CustomerReviews,
} from "../Ecommerce/EcommerceOrders/EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
// import EcommerceOrdersModal from "../EcommerceOrders/EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

function ApproveCustomer() {
  //meta title
  document.title = "Orders | Mykilo - React Admin & Dashboard Template";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [order, setOrder] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      billingName: (order && order.billingName) || "",
      orderdate: (order && order.orderdate) || "",
      total: (order && order.total) || "",
      paymentStatus: (order && order.paymentStatus) || "Paid",
      badgeclass: (order && order.badgeclass) || "success",
      paymentMethod: (order && order.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateOrder(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectEcommerceState = (state) => state.ecommerce;
  const EcommerceOrderProperties = createSelector(
    selectEcommerceState,
    (Ecommerce) => ({
      orders: Ecommerce.orders,
      loading: Ecommerce.loading,
    })
  );

  const { orders, loading } = useSelector(EcommerceOrderProperties);

  const [isLoading, setLoading] = useState(loading);

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders());
    }
  }, [dispatch, orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = (arg) => {
    const order = arg;
    setOrder({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    });

    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      //   {
      //     Header: () => (
      //       <div className="form-check font-size-16">
      //         <input className="form-check-input" type="checkbox" id="checkAll" />
      //         <label className="form-check-label" htmlFor="checkAll"></label>
      //       </div>
      //     ),
      //     accessor: "#",
      //     width: "20px",
      //     filterable: true,
      //     Cell: (cellProps) => (
      //       <div className="form-check font-size-16">
      //         <input className="form-check-input" type="checkbox" id="checkAll" />
      //         <label className="form-check-label" htmlFor="checkAll"></label>
      //       </div>
      //     ),
      //   },
      {
        Header: "User ID",
        accessor: "orderId",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Customer Name",
        accessor: "billingName",
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },

      //   {
      //     Header: "Payment Status",
      //     accessor: "paymentStatus",
      //     filterable: true,
      //     Cell: (cellProps) => {
      //       return <PaymentStatus {...cellProps} />;
      //     },
      //   },
      {
        Header: "Customer Review",
        accessor: "customer review",
        Cell: (cellProps) => {
          return <CustomerReviews {...cellProps} />;
        },
      },
      {
        Header: "Action",
        accessor: "duration",
        Cell: (cellProps) => {
          return (
            <>
              <div className="d">
                <Button
                  type="button"
                  //           // color="primary"
                  style={{ backgroundColor: "#008000" }}
                  className="btn-sm btn-rounded mr-2"
                  onClick={toggleViewModal}
                >
                  Accept
                </Button>
                <Button
                  type="button"
                  //           // color="primary"
                  style={{ backgroundColor: "#9C3022" }}
                  className="btn-sm btn-rounded"
                  onClick={toggleViewModal}
                >
                  Reject
                </Button>
              </div>
            </>
          );
        },
      },

      //   {
      //     Header: "View Details",
      //     accessor: "view",
      //     disableFilters: true,
      //     Cell: () => {
      //       return (
      //         <Button
      //           type="button"
      //           // color="primary"
      //           style={{ backgroundColor: "#9C3022" }}
      //           className="btn-sm btn-rounded"
      //           onClick={toggleViewModal}
      //         >
      //           View Details
      //         </Button>
      //       );
      //     },
      //   },
      //   {
      //     Header: "Action",
      //     accessor: "action",
      //     disableFilters: true,
      //     Cell: (cellProps) => {
      //       return (
      //         <div className="d-flex gap-3">
      //           <Link
      //             to="#"
      //             className="text-success"
      //             onClick={() => {
      //               const orderData = cellProps.row.original;
      //               handleOrderClick(orderData);
      //             }}
      //           >
      //             <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
      //             <UncontrolledTooltip placement="top" target="edittooltip">
      //               Edit
      //             </UncontrolledTooltip>
      //           </Link>
      //           <Link
      //             to="#"
      //             className="text-danger"
      //             onClick={() => {
      //               const orderData = cellProps.row.original;
      //               onClickDelete(orderData);
      //             }}
      //           >
      //             <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
      //             <UncontrolledTooltip placement="top" target="deletetooltip">
      //               Delete
      //             </UncontrolledTooltip>
      //           </Link>
      //         </div>
      //       );
      //     },
      //   },
    ],
    []
  );

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Customer Reviews" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={orders}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      handleOrderClicks={handleOrderClicks}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {/* <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Order" : "Add Order"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Order Id</Label>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="Insert Order Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.orderId || ""}
                        invalid={
                          validation.touched.orderId &&
                          validation.errors.orderId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.orderId &&
                      validation.errors.orderId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.orderId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ""}
                        invalid={
                          validation.touched.billingName &&
                          validation.errors.billingName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.billingName &&
                      validation.errors.billingName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.billingName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Order Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="orderdate"
                        placeholder="Select time"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(date) =>
                          validation.setFieldValue(
                            "orderdate",
                            moment(date[0]).format("DD MMMM, YYYY")
                          )
                        }
                        value={validation.values.orderdate}
                      />
                      {validation.touched.orderdate &&
                      validation.errors.orderdate ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.orderdate}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">
                          {validation.errors.total}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentStatus || ""}
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus &&
                      validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentStatus}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass &&
                      validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.badgeclass}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.paymentMethod || ""}
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod &&
                      validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.paymentMethod}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn  save-user"
                        style={{ backgroundColor: "#9C3022", color: "white" }}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal> */}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
ApproveCustomer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ApproveCustomer;

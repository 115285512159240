import axios from "../index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { successToster, errorToster } from "../api-response-toaster";
export const upLoadWorkOutVideoHandler = async (formData, setIsLoading) => {
  try {
    const response = await axios.post("/admin/upload/video", formData);
    const message = await response?.data?.message;
    successToster(message, "top-right");

    setIsLoading(false);
    return response;
  } catch (error) {
    errorToster(error?.response?.data?.error, "top-right");

    setIsLoading(false);
  }
};

export const getWorkOutVideoApiHandler = async (page) => {
  try {
    const response = await axios.get(`admin/videos/list?page=${page}`);
    const data = response?.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteWorkOutVideoByIDApiHandler = async (videoId, setLoading) => {
  try {
    const response = await axios.delete("admin/deleteWorkoutVideo", {
      data: {
        videoId: videoId,
      },
    });
    const data = await response?.data;

    successToster(data?.message, "top-right");

    setLoading(false);
    return data;
  } catch (error) {
    errorToster(error?.response?.data?.error, "top-right");
    setLoading(false);
  }
};

export const getWorkOutVideoByIdApiHandler = async (id) => {
  try {
    const response = await axios.get(`/admin/getUniqueVideo/${id}`);
    const data = response?.data;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const upDateWorkOutVideoHandler = async (formData, setIsLoading, id) => {
  try {
    const response = await axios.put(
      `/admin/updateWorkoutVideo/${id}`,
      formData
    );
    const message = await response?.data?.message;

    successToster(message, "top-right");

    setIsLoading(false);
    return response;
  } catch (error) {
    errorToster(error?.message, "top-right");

    setIsLoading(false);
  }
};

export const upDateThumbnailByIdHandler = async (id, formData) => {
  try {
    const response = await axios.put(`/admin/updateThumbnail/${id}`, formData);
    const data = await response?.data;
    successToster(data?.message, "top-right");

    return response;
  } catch (error) {
    errorToster(error?.message, "top-right");
  }
};

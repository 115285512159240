// import React from "react";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import {
//   FormGroup,
//   Label,
//   Col,
//   Row,
//   Card,
//   CardBody,
//   CardTitle,
//   Container,
//   Form,
// } from "reactstrap";
// import axios from "apis";

// const UploadRecipeVideo = () => {
//   const formik = useFormik({
//     initialValues: {
//       video: null,
//     },
//     validationSchema: Yup.object({
//       video: Yup.mixed().required("A video file is required"),
//     }),
//     onSubmit: async (values, { setSubmitting }) => {
//       const formData = new FormData();
//       formData.append("video", values.video);

//       try {
//         const response = await axios.post("/admin/add/video", formData, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });
//         console.log("Video uploaded successfully:", response.data);
//       } catch (error) {
//         console.error("Error uploading video:", error);
//       } finally {
//         setSubmitting(false);
//       }
//     },
//   });

//   const handleChange = (event) => {
//     formik.setFieldValue("video", event.currentTarget.files[0]);
//   };

//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Row>
//             <Col lg="12">
//               <Card>
//                 <CardBody>
//                   <CardTitle className="mb-4">Upload Recipe Video</CardTitle>

//                   <Form
//                     className="mb-4"
//                     row
//                     onSubmit={(e) => {
//                       e.preventDefault(); // Prevent default form submission
//                       formik.handleSubmit(); // Call formik submission handler
//                     }}
//                   >
//                     <div
//                       className="video-upload-container rounded bg-white"
//                       style={{
//                         marginTop: "10px",
//                         width: "100%",
//                         height: "350px",
//                         border: "1px solid black",
//                         position: "relative",
//                       }}
//                     >
//                       <label
//                         htmlFor="videoInput"
//                         style={{
//                           position: "absolute",
//                           top: "50%",
//                           left: "50%",
//                           transform: "translate(-50%, -50%)",
//                           cursor: "pointer",
//                         }}
//                       >
//                         <i className="display-4 text-muted bx bxs-cloud-upload" />
//                         <h5 className="ml-3 text-center">Add your video</h5>
//                       </label>
//                       <input
//                         id="videoInput"
//                         name="video"
//                         type="file"
//                         accept="video/*"
//                         onChange={handleChange}
//                         style={{ display: "none" }}
//                       />
//                       {formik.values.video && (
//                         <div
//                           style={{
//                             position: "relative",
//                             zIndex: 1,
//                             height: "100%",
//                             width: "100%",
//                           }}
//                         >
//                           <video
//                             width="100%"
//                             height="100%"
//                             controls
//                             style={{
//                               position: "absolute",
//                               top: 0,
//                               left: 0,
//                             }}
//                           >
//                             <source
//                               src={URL.createObjectURL(formik.values.video)}
//                               type="video/mp4"
//                             />
//                             Your browser does not support the video tag.
//                           </video>
//                         </div>
//                       )}
//                     </div>
//                     <div className="mt-2 d-flex justify-content-center align-items-center">
//                       <button
//                         type="submit"
//                         style={{ backgroundColor: "#9c2f21" }}
//                         className="btn btn-primary"
//                         disabled={formik.isSubmitting}
//                       >
//                         Upload Video
//                       </button>
//                     </div>
//                     {formik.touched.video && formik.errors.video && (
//                       <p className="text-danger">{formik.errors.video}</p>
//                     )}
//                   </Form>
//                 </CardBody>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default UploadRecipeVideo;
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  Label,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Container,
  Form,
} from "reactstrap";
import axios from "apis"; // Ensure axios is imported correctly

const UploadRecipeVideo = ({ onUploadSuccess }) => {
  const formik = useFormik({
    initialValues: {
      video: null,
    },
    validationSchema: Yup.object({
      video: Yup.mixed().required("A video file is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      formData.append("video", values.video);

      try {
        const response = await axios.post("/admin/add/video", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("Video uploaded successfully:", response.data);
        onUploadSuccess(response.data); // Call the callback function with the response data
      } catch (error) {
        console.error("Error uploading video:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleChange = (event) => {
    formik.setFieldValue("video", event.currentTarget.files[0]);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Upload Recipe Video</CardTitle>

                  <Form
                    className="mb-4"
                    row
                    onSubmit={(e) => {
                      e.preventDefault(); // Prevent default form submission
                      formik.handleSubmit(); // Call formik submission handler
                    }}
                  >
                    <div
                      className="video-upload-container rounded bg-white"
                      style={{
                        marginTop: "10px",
                        width: "100%",
                        height: "350px",
                        border: "1px solid black",
                        position: "relative",
                      }}
                    >
                      <label
                        htmlFor="videoInput"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          cursor: "pointer",
                        }}
                      >
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                        <h5 className="ml-3 text-center">Add your video</h5>
                      </label>
                      <input
                        id="videoInput"
                        name="video"
                        type="file"
                        accept="video/*"
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      {formik.values.video && (
                        <div
                          style={{
                            position: "relative",
                            zIndex: 1,
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <video
                            width="100%"
                            height="100%"
                            controls
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          >
                            <source
                              src={URL.createObjectURL(formik.values.video)}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                    </div>
                    <div className="mt-2 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        style={{ backgroundColor: "#9c2f21" }}
                        className="btn btn-primary"
                        disabled={formik.isSubmitting}
                      >
                        Upload Video
                      </button>
                    </div>
                    {formik.touched.video && formik.errors.video && (
                      <p className="text-danger">{formik.errors.video}</p>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UploadRecipeVideo;

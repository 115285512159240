import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  CourseOptions,
  displayEducationOptions,
  educationOptions,
  convenienceOptons,
  preferenceOptions,
  recipeCategories,
} from "../../utils/recipe-constant";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import * as Yup from "yup";
import { FieldArray, useFormik } from "formik";
import moment from "moment";
import { getSingleRecipes, getSingleRecipesSuccess } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { uploadRecipeVideo } from "apis/recipe";
import UploadRecipeVideo from "./upload-recipe-video";

const UploadUpdateRecipe = ({
  heading1,
  vname,
  Description,
  date,
  title,
  uploadbutton,
  attach,
}) => {
  //meta title
  document.title = "mykilo";
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  };
  //parms
  const { id } = useParams();

  // navigate
  const navigation = useNavigate();

  const [selectedFiles, setselectedFiles] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [recipe, setrecipe] = useState([]);
  const [valuebool, setValue] = useState(0);
  const [present, setPresent] = useState(0);
  const [slectedCourse, setSelectedCourse] = useState([]);
  const [selectedConvenience, setSelectedConvenience] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [selectedcategory, setSelectedcCategory] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    sub_title: "",
    video: "",
  });
  const [errors, setErrors] = useState({});

  const [selectedPreference, setSelectedPreference] = useState([]);
  const [isNewCoverImage, setisNewCoverImage] = useState(0);
  const [newrecipe, setNewrecipe] = useState([]);
  const Recipes = useSelector((state) => state.Recipe.recipes.recipe);
  //data update
  const [initialValues, setInitialValues] = useState({
    addPoints: [
      {
        Heading: "",
        details: [],
      },
    ],
    description: "",
    education: [],
    coverImage: null,
    recipe_Categorie: [],
    courseCategories: [],
    convenienceCategories: [],
    preferenceCategories: [],
    recipe: [],
    dietary_restriction: [],
    duration: "",
    servings: "",
    endDate: "",
    prepTime: "",
    cookTime: "",
    totalTime: "",
    notes: "",
    recipes: [
      {
        recipeHeading: "",
        ingredients: [
          {
            name: "",
            quantity: "",
          },
        ],
      },
    ],
  });

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [videoData, setVideoData] = useState(null);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const handleVideoUploadSuccess = (data) => {
    setVideoData(data);
    setIsVideoUploaded(true);
    toast.success("Video uploaded successfully!", { autoClose: 2000 });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (id && Recipes) {
      // If id exists and Recipes data is available
      const notesString = Recipes.Notes.join("\n"); // Convert array of notes to string
      setCoverImage(Recipes?.recipe_Cover_picture);
      setrecipe(Recipes.recipe_pictures);

      setInitialValues({
        title: Recipes.recipe_title,
        addPoints: Recipes.addPoints, // Populate with data from Recipes
        description: Recipes.recipe_description,
        // education: Recipes.dietary_restriction,
        coverImage: Recipes.recipe_Cover_picture,
        recipe_Categorie: Recipes?.recipe_Categorie?.map((recipeCategory) => ({
          label: recipeCategory?.replace(/_/g, " "),
          value: recipeCategory,
        })),
        dietary_restriction: Recipes?.dietary_restriction?.map(
          (dietaryRestriction) => ({
            label: dietaryRestriction?.replace(/_/g, " "),
            value: dietaryRestriction,
          })
        ),
        // courseCategories:Recipes?. .map((recipeCategory) => ({
        //   label: recipeCategory?.replace(/_/g, " "),
        //   value: recipeCategory,
        // })),
        convenienceCategories: [],
        preferenceCategories: [],
        recipe: Recipes.recipe_pictures,
        duration: "",
        servings: Recipes.servings,
        endDate: "",
        prepTime: Recipes.PREP_TIME,
        cookTime: Recipes.COOK_TIME,
        totalTime: Recipes.TOTAL_TIME,
        notes: notesString, // Set the notes as a string
        recipes: Recipes.recipes?.map((recipe) => ({
          recipeHeading: recipe.recipeHeading,
          ingredients: recipe.ingredients?.map((ingredient) => ({
            name: ingredient.name,
            quantity: ingredient.quantity,
          })),
        })),
      });

      setrecipe(Recipes.recipe_pictures);
      setCoverImage(Recipes.recipe_Cover_picture);
    } else {
      // If id doesn't exist or Recipes data is not available
      setInitialValues({
        addPoints: [
          {
            Heading: "",
            details: [],
          },
        ],
        description: "",
        education: [],
        coverImage: null,
        recipe_Categorie: [],
        courseCategories: [],
        convenienceCategories: [],
        preferenceCategories: [],
        recipe: [],
        dietary_restriction: [],
        duration: "",
        servings: "",
        endDate: "",
        prepTime: "",
        cookTime: "",
        totalTime: "",
        notes: "",
        recipes: [
          {
            recipeHeading: "",
            ingredients: [
              {
                name: "",
                quantity: "",
              },
            ],
          },
        ],
      });
    }
  }, [Recipes, id]);

  //use effect
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Dispatch the saga function when the component mounts
        if (id) {
          dispatch(getSingleRecipes(id));
        }
      } catch (error) {
        // Handle error if needed
        console.error("Error fetching recipes:", error);
      }
    };

    fetchData();
  }, [id]);
  // function handling
  const handleCoverImageChange = (files) => {
    const file = files[0];
    setisNewCoverImage(1);
    setCoverImage(file);
    validation.setFieldValue("coverImage", file);
  };
  function appendArrayWithIndex(formData, array, keyName) {
    array.forEach((item, index) => {
      formData.append(`${keyName}[${index}]`, item.value);
    });
  }

  const handleDeleteHeading = (index) => {
    const updatedAddPoints = [...validation.values.addPoints];
    updatedAddPoints.splice(index, 1);
    validation.setFieldValue("addPoints", updatedAddPoints);
    validation.setFieldTouched(`addPoints[${index}].Heading`, false);
  };

  const handleEducationChange = (selectedOptions) => {
    setSelectedEducation(selectedOptions);
  };
  const handleCategoryChange = (value, setValue) => {
    setValue(value);
  };
  const handleDeleteImage = (index) => {
    const updatedRecipe = [...recipe];
    updatedRecipe.splice(index, 1);
    setrecipe(updatedRecipe);
  };
  // Function to handle unchanged images
  // Modified handlerecipeChange function
  const handlerecipeChange = (files) => {
    // If no files are added, include unchanged images
    if (files.length === 0) {
      handleUnchangedImages();
      return;
    }

    setValue(1);

    // Append new files to the recipe state
    setrecipe((prevrecipe) => [...prevrecipe, ...files]);

    // Append new files to the form data
    validation.setFieldValue("recipe", [...recipe, ...files]);
  };

  // handle video change
  const handleChange = (event, key) => {
    const { name, value, type, checked, files } = event.target;
    const newInputs = { ...inputs };

    if (type === "file") {
      newInputs[name] = files[0];
    } else {
      newInputs[name] = value;
    }

    setInputs(newInputs);
    setErrors({ ...errors, [name]: null });
  };

  // Function to handle unchanged images
  const handleUnchangedImages = () => {
    // Append all existing images to the form data
    validation.setFieldValue("recipe", Recipes?.recipe_pictures);
  };

  // Modified handlerecipeChange function

  const convertCoverImageToBinaryIfRequired = async (coverImage) => {
    if (coverImage instanceof File && coverImage.type.startsWith("image/")) {
      // If cover image is not already in binary format, convert it
      if (
        !coverImage.type.includes("image/jpeg") &&
        !coverImage.type.includes("image/png")
      ) {
        convertImageToBinary(coverImage);
      }
    }
    return coverImage;
  };

  // Function to convert image to binary format
  const convertImageToBinary = async (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  };
  const handleAddrecipe = () => {
    setrecipe([...recipe, ...newrecipe]);
    validation.setFieldValue("recipe", [...recipe, ...newrecipe]);
    setNewrecipe([]);
  };
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initialValues,

    validationSchema: Yup.object({
      description: Yup.string().required("Please Enter Your Recipe desc"),
      notes: Yup.string().required("Please Enter Your Recipe Notes "),
      dietary_restriction: Yup.array().min(
        1,
        "At least one diet entry is required"
      ),
      recipe_Categorie: Yup.array().min(
        1,
        "At least one Recipe category is required"
      ),
      courseCategories: Yup.array().min(
        1,
        " At least one Caourse category is required"
      ),
      convenienceCategories: Yup.array().min(
        1,
        " At least one convenience category is required"
      ),
      preferenceCategories: Yup.array().min(
        1,
        " At least one preference category is required"
      ),

      prepTime: Yup.string().required("Please Enter Preparation Time"),
      servings: Yup.string().required("Please Enter Servings Time"), // Add validation for prepTime
      cookTime: Yup.string().required("Please Enter Cooking Time"), // Add validation for cookTime
      totalTime: Yup.string().required("Please Enter Total Time"),
      coverImage: Yup.mixed().test(
        "fileDimensions",
        "Image dimensions must be 1920 x 1320 pixels",
        async function (value) {
          if (!value || !isNewCoverImage) return true; // If no image uploaded, return true
          const file = value;
          const image = new Image();
          image.src = URL.createObjectURL(file);
          await new Promise((resolve) => {
            image.onload = resolve;
          }); // Wait for image to load
          const { width, height } = image;
          return width === 1920 && height === 1320;
        }

        // .test(
        //   "fileSize",
        //   "File size is too large",
        //   (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
        // )
      ),

      // recipe: Yup.array().of(
      //   Yup.mixed().test(
      //     "fileDimensions",
      //     "Image dimensions must be at most 479 x 639 pixels",
      //     async function (value) {
      //       if (!value) return true; // If no image uploaded, return true
      //       const file = value;
      //       const image = new Image();
      //       image.src = URL?.createObjectURL(file);
      //       await new Promise((resolve) => {
      //         image.onload = resolve;
      //       }); // Wait for image to load
      //       const { width, height } = image;
      //       return width <= 479 && height <= 639;
      //     }
      //   )
      // ),

      recipe: Yup.array().of(
        Yup.mixed().test(
          "fileDimensions",
          "Image dimensions must be at most 479 x 639 pixels",
          async function (value) {
            if (!value) return true; // If no image uploaded, return true

            // Check if the value is a valid File object
            if (!(value instanceof File)) {
              return this.createError({ message: "Invalid file type" });
            }

            const file = value;
            const image = new Image();
            let objectUrl;

            try {
              objectUrl = URL.createObjectURL(file);
            } catch (error) {
              console.error("Error creating object URL:", error);
              return this.createError({ message: "Failed to load image" });
            }

            image.src = objectUrl;

            await new Promise((resolve) => {
              image.onload = resolve;
            });

            // Revoke the object URL after the image is loaded
            URL.revokeObjectURL(objectUrl);

            const { width, height } = image;
            return width <= 479 && height <= 639;
          }
        )
      ),

      recipes: Yup.array().of(
        Yup.object().shape({
          recipeHeading: Yup.string().required("Please Enter Recipe Heading"),
          ingredients: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Please Enter Ingredient Name"),
              quantity: Yup.string().required(
                "Please Enter Ingredient Quantity"
              ),
            })
          ),
        })
      ),
    }),

    // onSubmit: async (values) => {
    //   try {
    //     setIsSubmitting(true);
    //     const jsonStringifiedIngredients = JSON.stringify(values.recipes);

    //     let cleanedAddPoints;
    //     if (!Recipes) {
    //       cleanedAddPoints = values?.addPoints?.map(
    //         ({ newDetail, ...rest }) => rest
    //       );
    //     } else {
    //       cleanedAddPoints = values?.addPoints?.map(
    //         ({ _id, newDetail, ...rest }) => rest
    //       );
    //     }

    //     const formData = {
    //       recipe_title: values.recipe_title,
    //       recipe_description: values.description,
    //       servings: values.servings,
    //       cookTime: values.cookTime,
    //       prepTime: values.prepTime,
    //       totalTime: values.totalTime,
    //       recipe_Categorie: appendArray(values.recipe_Categorie),
    //       dietary_restriction: appendArray(values.dietary_restriction),
    //     };

    //     // const formData = new FormData();
    //     const convertedCoverImage = await convertCoverImageToBinaryIfRequired(
    //       values.coverImage
    //     );
    //     const selectedEducationValues = selectedEducation.map(
    //       (option) => option.value
    //     );

    //     // formData.append("recipe_title", values.title.replace(/\s+/g, "_"));
    //     // formData.append("recipe_description", values.description);
    //     // formData.append("servings", values.servings);
    //     // formData.append("addPoints", JSON.stringify(cleanedAddPoints));
    //     // formData.append("COOK_TIME", values.cookTime);
    //     // formData.append("PREP_TIME", values.prepTime);
    //     // formData.append("TOTAL_TIME", values.totalTime);
    //     // formData.append(
    //     //   "dietary_restriction",
    //     //   JSON.stringify(selectedEducationValues)
    //     // );
    //     // formData.append("Notes", values.notes);
    //     // appendArrayWithIndex(formData, selectedcategory, "recipe_Categorie");

    //     // formData.append("recipe_Categorie", values.recipe_Categorie);
    //   appendArrayWithIndex(formData, selectedcategory, "recipe_Categorie");

    //     // formData.append("recipes", JSON.stringify(values.recipes));
    //     if (!Recipes) {
    //       formData.append("recipe_Cover_picture", values.coverImage);
    //     }

    //     // Check if new side images are added

    //     // if (Recipes) {
    //     // Check if new side images are added
    //     // const newSideImages = values.recipe.filter(
    //     //   (image) => !Recipes.recipe_pictures.includes(image)
    //     // );
    //     // Assuming recipe is an array of items that need to be converted to File objects
    //     recipe.forEach((item, index) => {
    //       let file;

    //       // Check if the item is already a File object
    //       if (item instanceof File) {
    //         file = item; // If it's a File object, use it directly
    //       } else {
    //         // If it's not a File object, create a new File object
    //         const blob = new Blob([item], { type: "image/png" });
    //         file = new File([blob], `recipe_${index}.png`, {
    //           type: "image/png",
    //         });
    //       }

    //       // Append the File object to the formData
    //       formData.append("recipe_pictures", file);
    //     });

    //     // } else {
    //     //   // Append all side images

    //     //   values.recipe.map((image) => {
    //     //     formData.append("recipe_pictures", image);
    //     //   });
    //     // }
    //     //formData.append("recipe_Cover_picture", values.coverImage);
    //     let response;
    //     const apiUrl = Recipes
    //       ? `https://api.mykilo.co.uk/admin/update/recipe/${id}`
    //       : "https://api.mykilo.co.uk/admin/upload/recipe";
    //     const method = Recipes ? "PUT" : "POST";
    //     let statusMessage = Recipes
    //       ? "Recipe Updated Successfully"
    //       : "Recipe added Successfully";

    //     response = await fetch(apiUrl, {
    //       method: method,
    //       body: formData,
    //       headers: {
    //         Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
    //       },
    //     });

    //     if (response?.status === 200) {
    //       toast.success(statusMessage, { autoClose: 2000 });

    //       setTimeout(() => {
    //         navigation("/Recipes");
    //         setIsSubmitting(false);
    //       }, 3000); // 3 second delay
    //     } else {
    //       //toast.error("Failed to add recipe", { autoClose: 2000 });
    //       setIsSubmitting(false);
    //     }
    //   } catch (error) {
    //     setIsSubmitting(false);
    //   }
    // },

    onSubmit: async (values) => {
      console.log("🚀 ~ onSubmit: ~ values:", values);
      if (!isVideoUploaded) {
        toast.error("Please upload the video before submitting.", {
          autoClose: 2000,
        });
        return;
      }
      try {
        setIsSubmitting(true);
        console.log("Submitting form with values:", values);

        const jsonStringifiedIngredients = JSON.stringify(values.recipes);
        let cleanedAddPoints;
        if (!Recipes) {
          cleanedAddPoints = values?.addPoints?.map(
            ({ newDetail, ...rest }) => rest
          );
        } else {
          cleanedAddPoints = values?.addPoints?.map(
            ({ _id, newDetail, ...rest }) => rest
          );
        }

        const formData = new FormData();
        formData.append("recipe_title", values.title);
        formData.append("recipe_description", values.description);
        formData.append("servings", values.servings);
        formData.append("cookTime", values.cookTime);
        formData.append("prepTime", values.prepTime);
        formData.append("TOTAL_TIME", values.totalTime);
        formData.append("recipes", jsonStringifiedIngredients);
        formData.append("addPoints", JSON.stringify(cleanedAddPoints));
        formData.append("Notes", values.notes);
        appendArrayWithIndex(
          formData,
          values?.recipe_Categorie,
          "recipe_Categorie"
        );

        formData.append(
          "dietary_restriction",
          JSON.stringify(values.dietary_restriction?.map((item) => item.value))
        );
        formData.append("recipe_video", videoData?.data?.recipe_video);
        const convertedCoverImage = await convertCoverImageToBinaryIfRequired(
          values.coverImage
        );
        const selectedEducationValues = selectedEducation.map(
          (option) => option.value
        );
        if (!Recipes) {
          formData.append("recipe_Cover_picture", values.coverImage);
        }

        values.recipe.forEach((item, index) => {
          let file;
          if (item instanceof File) {
            file = item;
          } else {
            const blob = new Blob([item], { type: "image/png" });
            file = new File([blob], `recipe_${index}.png`, {
              type: "image/png",
            });
          }
          formData.append("recipe_pictures", file);
        });

        const apiUrl = Recipes
          ? `https://api.mykilo.co.uk/admin/update/recipe/${id}`
          : "https://api.mykilo.co.uk/admin/upload/recipe";
        const method = Recipes ? "PUT" : "POST";
        const statusMessage = Recipes
          ? "Recipe Updated Successfully"
          : "Recipe added Successfully";

 
        const response = await fetch(apiUrl, {
          method: method,
          body: formData,
          headers: {
            Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
          },
        });

        console.log("API response status:", response.status);
        if (response?.status === 200) {
          toast.success(statusMessage, { autoClose: 2000 });

          setTimeout(() => {
            navigation("/Recipes");
            setIsSubmitting(false);
          }, 3000);
        } else {
          toast.error("Failed to add recipe", { autoClose: 2000 });
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        setIsSubmitting(false);
      }
      console.log("🚀 ~ onSubmit: ~ recipe_Categorie:", recipe_Categorie);
    },
  });
  // console.log(validation, "sahdjhdajhdsjh");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem={title} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{heading1}</CardTitle>
                  <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        {/* {vname} */}
                        Name
                      </Label>
                      <Col lg="10">
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                          invalid={
                            validation.touched.title && validation.errors.title
                              ? true
                              : false
                          }
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-lg-2"
                      >
                        {/* {Description} */}
                        Description
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="description"
                          rows="3"
                          name="description"
                          placeholder="Enter Description..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="notes"
                        className="col-form-label col-lg-2"
                      >
                        Recipe Notes
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="notes"
                          rows="3"
                          name="notes"
                          placeholder="Enter Notes..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.notes || ""}
                        />
                        {validation.touched.notes && validation.errors.notes ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.notes}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="prepTime"
                        className="col-form-label col-lg-2"
                      >
                        Preparation Time
                      </Label>
                      <Col lg="10">
                        <Input
                          id="prepTime"
                          name="prepTime"
                          type="text"
                          className="form-control"
                          placeholder="Enter Preparation Time like 12 min..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.prepTime || ""}
                          invalid={
                            validation.touched.prepTime &&
                            validation.errors.prepTime
                              ? true
                              : false
                          }
                        />
                        {validation.touched.prepTime &&
                        validation.errors.prepTime ? (
                          <FormFeedback type="invalid">
                            {validation.errors.prepTime}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="cookTime"
                        className="col-form-label col-lg-2"
                      >
                        Cooking Time
                      </Label>
                      <Col lg="10">
                        <Input
                          id="cookTime"
                          name="cookTime"
                          type="text"
                          className="form-control"
                          placeholder="Enter Cooking Time like 12 min..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.cookTime || ""}
                          invalid={
                            validation.touched.cookTime &&
                            validation.errors.cookTime
                              ? true
                              : false
                          }
                        />
                        {validation.touched.cookTime &&
                        validation.errors.cookTime ? (
                          <FormFeedback type="invalid">
                            {validation.errors.cookTime}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="totalTime"
                        className="col-form-label col-lg-2"
                      >
                        Total Time
                      </Label>
                      <Col lg="10">
                        <Input
                          id="totalTime"
                          name="totalTime"
                          type="text"
                          className="form-control"
                          placeholder="Enter Total Time 12 min.."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.totalTime || ""}
                          invalid={
                            validation.touched.totalTime &&
                            validation.errors.totalTime
                              ? true
                              : false
                          }
                        />
                        {validation.touched.totalTime &&
                        validation.errors.totalTime ? (
                          <FormFeedback type="invalid">
                            {validation.errors.totalTime}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Dietary Restrictions{" "}
                      </Label>
                      <Col lg={10}>
                        {/* <Select
                          id="education"
                          name="education"
                          isMulti
                          value={selectedEducation}
                          // value={validation.values.dietary_restriction}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            handleEducationChange(selectedOptions);
                            validation.setFieldValue(
                              "education",
                              selectedOptions
                            );
                            validation.handleBlur("education");
                          }}
                          options={educationOptions?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        /> */}
                        <Select
                          isMulti
                          id="dietary_restriction"
                          name="dietary_restriction"
                          // value={selectedcategory}
                          value={validation.values.dietary_restriction}
                          styles={customStyles}
                          onChange={(selectedcategory) => {
                            handleCategoryChange(
                              selectedcategory,
                              setSelectedcCategory
                            );
                            validation.setFieldValue(
                              "dietary_restriction",
                              selectedcategory
                            );
                            validation.handleBlur("dietary_restriction");
                          }}
                          options={educationOptions?.map((option) => ({
                            // Modified line
                            value: option,
                            label: option?.replace(/_/g, " "), // Changed `recipeCategories[option]` to `option`
                          }))}
                        />
                        {validation.touched.education &&
                          validation.errors.education && (
                            <div className="text-danger">
                              {validation.errors.education}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="servings"
                        className="col-form-label col-lg-2"
                      >
                        Servings
                      </Label>
                      <Col lg="10">
                        <Input
                          id="servings"
                          name="servings"
                          type="text"
                          className="form-control"
                          placeholder="Enter Number of Servings"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.servings || ""}
                          invalid={
                            validation.touched.servings &&
                            validation.errors.servings
                              ? true
                              : false
                          }
                        />
                        {validation.touched.servings &&
                        validation.errors.servings ? (
                          <FormFeedback type="invalid">
                            {validation.errors.servings}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">Course</Label>
                      <Col lg={10}>
                        <Select
                          id="courseCategories"
                          name="courseCategories"
                          isMulti
                          value={slectedCourse}
                          styles={customStyles}
                          onChange={(selectedcategory) => {
                            handleCategoryChange(
                              selectedcategory,
                              setSelectedCourse
                            );
                            validation.setFieldValue(
                              "courseCategories",
                              selectedcategory
                            );
                            validation.handleBlur("courseCategories");
                          }}
                          options={CourseOptions.map((option) => ({
                            // Modified line
                            value: option,
                            label: option, // Changed `recipeCategories[option]` to `option`
                          }))}
                        />
                        {validation.touched.courseCategories &&
                          validation.errors.courseCategories && (
                            <div className="text-danger">
                              {validation.errors.courseCategories}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Convenience
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="convenienceCategories"
                          name="convenienceCategories"
                          isMulti
                          value={selectedConvenience}
                          styles={customStyles}
                          onChange={(selectedcategory) => {
                            handleCategoryChange(
                              selectedcategory,
                              setSelectedConvenience
                            );
                            validation.setFieldValue(
                              "convenienceCategories",
                              selectedcategory
                            );
                            validation.handleBlur("convenienceCategories");
                          }}
                          options={convenienceOptons?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.convenienceCategories &&
                          validation.errors.convenienceCategories && (
                            <div className="text-danger">
                              {validation.errors.convenienceCategories}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Preference
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="preferenceCategories"
                          name="preferenceCategories"
                          isMulti
                          value={selectedPreference}
                          styles={customStyles}
                          onChange={(selectedcategory) => {
                            handleCategoryChange(
                              selectedcategory,
                              setSelectedPreference
                            );
                            validation.setFieldValue(
                              "preferenceCategories",
                              selectedcategory
                            );
                            validation.handleBlur("preferenceCategories");
                          }}
                          options={preferenceOptions.map((option) => ({
                            // Modified line
                            value: option,
                            label: option, // Changed `recipeCategories[option]` to `option`
                          }))}
                        />
                        {validation.touched.preferenceCategories &&
                          validation.errors.preferenceCategories && (
                            <div className="text-danger">
                              {validation.errors.preferenceCategories}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Recipe Category
                      </Label>
                      <Col lg={10}>
                        <Select
                          isMulti
                          id="recipe_Categorie"
                          name="recipe_Categorie"
                          // value={selectedcategory}
                          value={validation.values.recipe_Categorie}
                          styles={customStyles}
                          onChange={(selectedcategory) => {
                            handleCategoryChange(
                              selectedcategory,
                              setSelectedcCategory
                            );
                            validation.setFieldValue(
                              "recipe_Categorie",
                              selectedcategory
                            );
                            validation.handleBlur("recipe_Categorie");
                          }}
                          options={recipeCategories.map((option) => ({
                            // Modified line
                            value: option,
                            label: option?.replace(/_/g, " "), // Changed `recipeCategories[option]` to `option`
                          }))}
                        />
                        {validation.touched.recipe_Categorie &&
                          validation.errors.recipe_Categorie && (
                            <div className="text-danger">
                              {validation.errors.recipe_Categorie}
                            </div>
                          )}
                      </Col>
                    </Row>
                    {validation?.values?.recipes?.map((recipe, recipeIndex) => (
                      <div key={recipeIndex}>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor={`recipes[${recipeIndex}].recipeHeading`}
                            className="col-form-label col-lg-2"
                          >
                            Recipe Heading
                          </Label>
                          <Col lg="10">
                            <Input
                              id={`recipes[${recipeIndex}].recipeHeading`}
                              name={`recipes[${recipeIndex}].recipeHeading`}
                              type="text"
                              className="form-control"
                              placeholder="Enter Recipe Heading..."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={recipe.recipeHeading || ""}
                              invalid={
                                validation.touched.recipes &&
                                validation.errors.recipes &&
                                validation.errors.recipes[recipeIndex] &&
                                validation.errors.recipes[recipeIndex]
                                  .recipeHeading
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.recipes &&
                            validation.errors.recipes &&
                            validation.errors.recipes[recipeIndex] &&
                            validation.errors.recipes[recipeIndex]
                              .recipeHeading ? (
                              <FormFeedback type="invalid">
                                {
                                  validation.errors.recipes[recipeIndex]
                                    .recipeHeading
                                }
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </FormGroup>

                        {/* Ingredients Section */}
                        {recipe.ingredients.map((ingredient, index) => (
                          <div key={index}>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor={`recipes[${recipeIndex}].ingredients[${index}].name`}
                                className="col-form-label col-lg-2"
                              >
                                Ingredient Name
                              </Label>
                              <Col lg="7">
                                <Input
                                  id={`recipes[${recipeIndex}].ingredients[${index}].name`}
                                  name={`recipes[${recipeIndex}].ingredients[${index}].name`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Ingredient Name..."
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={ingredient.name || ""}
                                  invalid={
                                    validation.touched.recipes &&
                                    validation.errors.recipes &&
                                    validation.errors.recipes[recipeIndex] &&
                                    validation.errors.recipes[recipeIndex]
                                      .ingredients &&
                                    validation.errors.recipes[recipeIndex]
                                      .ingredients[index] &&
                                    validation.errors.recipes[recipeIndex]
                                      .ingredients[index].name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.recipes &&
                                validation.errors.recipes &&
                                validation.errors.recipes[recipeIndex] &&
                                validation.errors.recipes[recipeIndex]
                                  .ingredients &&
                                validation.errors.recipes[recipeIndex]
                                  .ingredients[index] &&
                                validation.errors.recipes[recipeIndex]
                                  .ingredients[index].name ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validation.errors.recipes[recipeIndex]
                                        .ingredients[index].name
                                    }
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col lg="3">
                                <Button
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "#9c2f21" }}
                                  onClick={() => {
                                    const newIngredients = [
                                      ...recipe.ingredients,
                                    ];
                                    newIngredients.splice(index, 1);
                                    validation.setFieldValue(
                                      `recipes[${recipeIndex}].ingredients`,
                                      newIngredients
                                    );
                                  }}
                                >
                                  Remove Ingredient
                                </Button>
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor={`recipes[${recipeIndex}].ingredients[${index}].quantity`}
                                className="col-form-label col-lg-2"
                              >
                                Ingredient Quantity
                              </Label>
                              <Col lg="7">
                                <Input
                                  id={`recipes[${recipeIndex}].ingredients[${index}].quantity`}
                                  name={`recipes[${recipeIndex}].ingredients[${index}].quantity`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Ingredient Quantity..."
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={ingredient.quantity || ""}
                                  invalid={
                                    validation.touched.recipes &&
                                    validation.errors.recipes &&
                                    validation.errors.recipes[recipeIndex] &&
                                    validation.errors.recipes[recipeIndex]
                                      .ingredients &&
                                    validation.errors.recipes[recipeIndex]
                                      .ingredients[index] &&
                                    validation.errors.recipes[recipeIndex]
                                      .ingredients[index].quantity
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.recipes &&
                                validation.errors.recipes &&
                                validation.errors.recipes[recipeIndex] &&
                                validation.errors.recipes[recipeIndex]
                                  .ingredients &&
                                validation.errors.recipes[recipeIndex]
                                  .ingredients[index] &&
                                validation.errors.recipes[recipeIndex]
                                  .ingredients[index].quantity ? (
                                  <FormFeedback type="invalid">
                                    {
                                      validation.errors.recipes[recipeIndex]
                                        .ingredients[index].quantity
                                    }
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col lg={3}>
                                <Button
                                  style={{ backgroundColor: "#9c2f21" }}
                                  onClick={() => {
                                    const newIngredients = [
                                      ...recipe.ingredients,
                                      { name: "", quantity: "" },
                                    ];
                                    validation.setFieldValue(
                                      `recipes[${recipeIndex}].ingredients`,
                                      newIngredients
                                    );
                                  }}
                                >
                                  Add Ingredient
                                </Button>
                              </Col>
                            </FormGroup>
                          </div>
                        ))}

                        {/* Add Ingredient Button */}
                        <FormGroup className="mb-4  " row>
                          <Col
                            lg={12}
                            className="d-flex justify-content-center gap-4 align-items-center"
                          >
                            <Col></Col>
                            <Col lg={6} className="d-flex justify-content-end">
                              <Button
                                style={{ backgroundColor: "#9c2f21" }}
                                onClick={() => {
                                  validation.setFieldValue("recipes", [
                                    ...validation.values.recipes,
                                    {
                                      recipeHeading: "",
                                      ingredients: [{ name: "", quantity: "" }],
                                    },
                                  ]);
                                }}
                              >
                                Add Recipe
                              </Button>
                            </Col>
                            <Col lg={6}>
                              <Button
                                style={{ backgroundColor: "#9c2f21" }}
                                onClick={() => {
                                  const newRecipes = [
                                    ...validation.values.recipes,
                                  ];
                                  newRecipes.splice(recipeIndex, 1);
                                  validation.setFieldValue(
                                    "recipes",
                                    newRecipes
                                  );
                                }}
                              >
                                Remove Recipe
                              </Button>
                            </Col>
                          </Col>
                        </FormGroup>
                      </div>
                    ))}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="addPoints"
                        className="col-form-label col-lg-2"
                      >
                        Recipe additional Points
                      </Label>
                      <Col lg="10">
                        {validation.values?.addPoints?.map((point, index) => (
                          <div key={index} className="mb-3 ">
                            <Col lg={12} className="d-flex  gap-4">
                              <Col lg={8}>
                                <Input
                                  type="text"
                                  name={`addPoints[${index}].Heading`}
                                  value={point?.Heading || ""}
                                  onChange={(e) => {
                                    validation.handleChange(e);
                                  }}
                                  onBlur={validation.handleBlur}
                                  placeholder="Enter Heading..."
                                />
                              </Col>

                              <Col lg={4}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "#9c2f21" }}
                                  onClick={() => {
                                    validation.setFieldValue("addPoints", [
                                      ...validation.values.addPoints,
                                      {
                                        Heading: "",
                                        details: [],
                                        newDetail: "",
                                      },
                                    ]);
                                  }}
                                >
                                  Add Heading
                                </button>
                              </Col>
                            </Col>
                            <div className="mt-2">
                              {point?.details?.map((detail, detailIndex) => (
                                <span
                                  key={detailIndex}
                                  className="badge bg-primary me-2"
                                >
                                  {detail.length > 100
                                    ? `${detail.substring(0, 100)}...`
                                    : detail}
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-1"
                                    onClick={() => {
                                      const updatedDetails = [...point.details];
                                      updatedDetails.splice(detailIndex, 1);
                                      validation.setFieldValue(
                                        `addPoints[${index}].details`,
                                        updatedDetails
                                      );
                                    }}
                                  ></button>
                                </span>
                              ))}
                            </div>
                            <Col lg={12}>
                              <Col
                                lg={12}
                                className="mt-2 gap-4 d-flex align-items-end"
                              >
                                <Col lg={8}>
                                  <Input
                                    type="text"
                                    name={`addPoints[${index}].newDetail`}
                                    value={point?.newDetail || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    placeholder="Enter Point..."
                                    className="mr-2" // Adjust margin as needed
                                  />
                                </Col>
                                <Col lg={3}>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{ backgroundColor: "#9c2f21" }}
                                    onClick={() => {
                                      validation.setFieldValue(
                                        `addPoints[${index}].details`,
                                        [...point.details, point.newDetail]
                                      );
                                      validation.setFieldValue(
                                        `addPoints[${index}].newDetail`,
                                        ""
                                      );
                                    }}
                                    disabled={!point.newDetail?.trim()} // Disable button if newDetail is empty
                                  >
                                    Add Point
                                  </button>
                                </Col>
                              </Col>
                            </Col>
                            <div className="mt-4">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ backgroundColor: "#9c2f21" }}
                                onClick={() => handleDeleteHeading(index)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        ))}
                      </Col>
                    </FormGroup>

                    {/* Add Recipe Button */}
                    {!Recipes ? (
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="coverImage"
                          className="col-form-label col-lg-2"
                        >
                          Cover Image
                        </Label>
                        <Col lg="10">
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleCoverImageChange(acceptedFiles)
                            }
                            accept="image/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div>
                                <div
                                  className="dropzone d-flex justify-content-center align-items-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  {!coverImage && (
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      <h4>
                                        Drop files here or click to upload Size
                                        must be 1920 x 1320
                                      </h4>
                                    </div>
                                  )}
                                  {coverImage && (
                                    <div className="image-preview-box mt-2 position-relative">
                                      <img
                                        src={
                                          coverImage && !isNewCoverImage
                                            ? `https://api.mykilo.co.uk/cover/${coverImage}`
                                            : URL?.createObjectURL(coverImage)
                                        }
                                        alt="Cover Image Preview"
                                        style={{
                                          maxHeight: "220px",
                                          maxWidth: "220px",
                                        }}
                                      />
                                      {/* Add delete button */}
                                      {coverImage && (
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                          style={{ zIndex: 1 }} // Ensure the button is above the image
                                          onClick={() => setCoverImage(null)} // This will delete the cover image
                                        >
                                          X
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="mt-2 d-flex justify-content-center align-items-center">
                                  <button
                                    type="button"
                                    style={{ backgroundColor: "#9c2f21" }}
                                    className="btn btn-primary"
                                    onClick={() => {
                                      // Trigger the file input click when the button is pressed
                                      document
                                        .querySelector('input[type="file"]')
                                        .click();
                                    }}
                                  >
                                    Upload Image
                                  </button>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          {validation.touched.coverImage &&
                          validation.errors.coverImage ? (
                            <div className="text-danger mt-2">
                              {validation.errors.coverImage}
                            </div>
                          ) : null}
                        </Col>
                      </FormGroup>
                    ) : null}

                    {/* Side Images */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="recipe"
                        className="col-form-label col-lg-2"
                      >
                        Side Images
                      </Label>
                      <Col lg="10">
                        <Dropzone
                          onDrop={(acceptedFiles) =>
                            handlerecipeChange(acceptedFiles)
                          }
                          accept="image/*"
                          multiple={true}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone d-flex flex-column justify-content-center align-items-center">
                              <div
                                {...getRootProps()}
                                className="d-flex justify-content-center align-items-center text-align-center"
                              >
                                <input
                                  {...getInputProps()}
                                  id="recipeInput"
                                  onChange={(e) =>
                                    handlerecipeChange(e.target.files)
                                  }
                                />
                                {recipe && recipe.length > 0 ? null : (
                                  <div className="d-flex justify-content-center flex-column align-items-center">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    <h4>
                                      Drop files here or click to upload Size
                                      must be 479 x 639
                                    </h4>
                                  </div>
                                )}
                              </div>

                              <div className="mt-3 d-flex justify-content-right align-items-right">
                                <div className="d-flex justify-content-left align-items-left gap-4">
                                  {recipe &&
                                    recipe?.map((file, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-left gap-4 position-relative"
                                      >
                                        <div className="d-flex justify-content-left gap-4 position-relative">
                                          <div className="image-preview-box">
                                            <img
                                              src={
                                                !valuebool ||
                                                Recipes?.recipe_pictures?.includes(
                                                  file
                                                )
                                                  ? `https://api.mykilo.co.uk/recipe/${file}`
                                                  : URL.createObjectURL(file)
                                              }
                                              alt={`Side Image ${
                                                index + 1
                                              } Preview`}
                                              className="img-fluid"
                                              style={{
                                                maxHeight: "200px",
                                                maxWidth: "200px",
                                              }}
                                            />
                                            {/* Add delete button */}
                                            <button
                                              type="button"
                                              className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                              onClick={() =>
                                                handleDeleteImage(index)
                                              }
                                            >
                                              x
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div className="mt-2 d-flex justify-content-center align-items-center">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ backgroundColor: "#9c2f21" }}
                            onClick={() => {
                              document.querySelector("#recipeInput").click();
                            }}
                          >
                            Upload Image
                          </button>
                        </div>
                        {validation.touched.recipe &&
                        validation.errors.recipe ? (
                          <div className="text-danger mt-2">
                            {validation.errors.recipe}
                          </div>
                        ) : null}
                        {/* Validation for side images */}
                        {validation.touched.sideImages &&
                        validation.errors.sideImages ? (
                          <div className="text-danger mt-2">
                            {validation.errors.sideImages}
                          </div>
                        ) : null}
                      </Col>
                    </FormGroup>

                    {/* Upload videos */}
                    {/* <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="recipe"
                        className="col-form-label col-lg-2"
                      >
                        Upload Recipe Video 
                      </Label>
                      <Col lg="10">
                        <div
                          className="video-upload-container rounded bg-white"
                          style={{
                            marginTop: "10px",
                            width: "100%",
                            height: "350px",
                            border: "1px solid black",
                            position: "relative",
                          }}
                        >
                          <label
                            htmlFor="videoInput"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              cursor: "pointer",
                            }}
                          >
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                            <h5 className="ml-3 text-center">Add your video</h5>
                          </label>
                          <input
                            id="videoInput"
                            name="video"
                            type="file"
                            accept="video/*"
                            onChange={(event) => handleChange(event, "video")}
                            style={{ display: "none" }}
                          />
                          {inputs.video && (
                            <div
                              style={{
                                position: "relative",
                                zIndex: 1,
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <video
                                width="100%"
                                height="100%"
                                controls
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                }}
                              >
                                <source
                                  src={URL.createObjectURL(inputs.video)}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                        </div>
                        <div className="mt-2 d-flex justify-content-center align-items-center">
                          <button
                            type="button"
                            style={{ backgroundColor: "#9c2f21" }}
                            className="btn btn-primary"
                            // onClick={() => uploadRecipeVideo()}

                            // onClick={() => {
                            //   // Trigger the file input click when the button is pressed
                            //   document
                            //     .querySelector('input[type="file"]')
                            //     .click();
                            // }}
                          >
                            Upload Video First
                          </button>
                        </div>
                      </Col>
                      {errors.video && (
                        <p className="text-danger">{errors.video}</p>
                      )}
                    </FormGroup> */}

                    <Row className="mt-5">
                      <Col
                        lg="12"
                        className="d-flex justify-content-end  align-items-center"
                      >
                        {/* <Button
                          type="submit"
                          style={{ backgroundColor: "#9C3022" }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit Recipe"}
                        </Button> */}
                        <Button
                          type="submit"
                          style={{ backgroundColor: "#9C3022" }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit Recipe"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <UploadRecipeVideo onUploadSuccess={handleVideoUploadSuccess} />
      <ToastContainer />
    </React.Fragment>
  );
};

export default UploadUpdateRecipe;

{
  /*

// recipe: Yup.array()
      //   .of(
      //     Yup.mixed()
      //       .test(
      //         "fileSize",
      //         "File size is too large",
      //         (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
      //       )
      //       .test(
      //         "fileDimensions",
      //         "Image dimensions must be 479 x 639 pixels",
      //         async function (value) {
      //           if (!value) return true; // If no image uploaded, return true
      //           const file = value;
      //           const image = new Image();
      //           image.src = URL.createObjectURL(file);
      //           await new Promise((resolve) => {
      //             image.onload = resolve;
      //           }); // Wait for image to load
      //           const { width, height } = image;
      //           return width === 479 && height === 639;
      //         }
      //       )
      //   )
      //   .min(3, "Please upload at least 3 side images")
      //   .test("fileCount", "Please upload at most 3 side images", (value) => {
      //     // Check if the length of the array (number of images uploaded) is at most 3
      //     return !value || value.length <= 3;
      //   }),
*/
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Paginations from "components/Common/Pagination";
import Spinners from "components/Common/Spinner";
import { getRecipes, deleteRecipe } from "store/Recipe/actions";
import { ToastContainer } from "react-toastify";

const Recipes = () => {
  document.title = "mykilo";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Recipes = useSelector(
    (state) => state?.Recipe?.recipes?.data?.recipies
  );

  const totalRecipes = useSelector(
    (state) => state?.Recipe?.recipes?.data?.totalCounts
  );

  const isLoading = useSelector((state) => state.Recipe.loading);
  const [loading, setLoading] = useState(isLoading);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const totalPages = Math.ceil(totalRecipes / perPageData);

  const handleDelete = (recipeid) => {
    dispatch(deleteRecipe(recipeid));
    dispatch(getRecipes(perPageData, currentPage));
  };

  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(getRecipes(perPageData, currentPage));
  //   setLoading(false);
  // }, [dispatch, perPageData, currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getRecipes(perPageData, currentPage));
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, perPageData, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(getRecipes(perPageData, page));
  };

  const handleNavigate = (id) => {
    navigate(`/recipe-edit/${id}`);
  };

  const [editingCardId, setEditingCardId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleToggleEdit = (id) => {
    setEditingCardId(editingCardId === id ? null : id);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImageUpload = async (id) => {
    if (!selectedFile) {
      console.error("No file selected.");
      return;
    }

    const formData = new FormData();
    formData.append("cover", selectedFile);

    try {
      const response = await fetch(
        `https://api.mykilo.co.uk/admin/updateCoverImage/${id}`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error uploading image.");
      }

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleUploadClick = (id) => {
    handleImageUpload(id);
  };

  // if (loading) {
  //   return <Spinners />;
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2">
                    <h5>Recipes</h5>
                  </div>
                </Col>
              </Row>
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <>
                  <Row>
                    {Recipes && Recipes?.length > 0 && !loading ? (
                      Recipes?.map((recipe, key) => (
                        <Col xl={4} sm={6} key={"_col_" + key}>
                          <div className="card-wrapper">
                            <Card>
                              <CardBody
                                className="p-4"
                                style={{ borderRadius: "10%", height: "600px" }}
                              >
                                <div className="recipe-img position-relative">
                                  {recipe.recipe_Cover_picture && (
                                    <>
                                      <img
                                        style={{
                                          height: "330px",
                                          borderRadius: "10px",
                                        }}
                                        src={`https://api.mykilo.co.uk/cover/${recipe.recipe_Cover_picture}`}
                                        alt=""
                                        className="img-fluid mx-auto d-block shadow-lg"
                                      />
                                      {editingCardId === recipe._id && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%",
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.5)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              textAlign: "center",
                                              color: "#fff",
                                            }}
                                          >
                                            <input
                                              type="file"
                                              accept="image/*"
                                              onChange={handleFileChange}
                                              style={{
                                                display: "block",
                                                marginBottom: "10px",
                                              }}
                                            />
                                            <button
                                              onClick={() =>
                                                handleUploadClick(recipe._id)
                                              }
                                              style={{
                                                backgroundColor: "#007bff",
                                                color: "#fff",
                                                border: "none",
                                                padding: "5px 15px",
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              Upload
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "10px",
                                          right: "10px",
                                        }}
                                      >
                                        <button
                                          onClick={() =>
                                            handleToggleEdit(recipe._id)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#9D3022",
                                            border: "none",
                                            padding: "5px 15px",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <i
                                            className="bx bx-edit-alt"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="mt-4 text-center">
                                  <h5 className="mb-3 text-truncate font-weight-bold">
                                    <Link className="text-dark text-decoration-none">
                                      {recipe.recipe_title}
                                    </Link>
                                  </h5>
                                  <hr
                                    className="w-25 mx-auto mb-3"
                                    style={{ borderTop: "2px solid #ddd" }}
                                  />
                                  <p
                                    className="text-muted"
                                    style={{
                                      overflow: "auto",
                                      maxHeight: "160px",
                                    }}
                                  >
                                    {recipe.recipe_description}
                                  </p>
                                </div>
                              </CardBody>
                              <div
                                className="d-flex justify-content-end gap-4 mb-3 mr-4"
                                style={{ marginRight: "32px" }}
                              >
                                <Button
                                  color="secondary"
                                  onClick={() => handleNavigate(recipe._id)}
                                  className="btn btn-secondary waves-effect waves-light"
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => handleDelete(recipe._id)}
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  Delete
                                </Button>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      ))
                    ) : (
                     !loading && <h4>No Recipes Found...</h4>
                    )}
                  </Row>
                  {Recipes && Recipes.length > 0 && (
                    <Paginations
                      currentPage={currentPage}
                      totalPages={totalPages}
                      setCurrentPage={handlePageChange}
                      paginationDiv="col-lg-12"
                      paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

Recipes.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(Recipes);

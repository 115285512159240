import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import withTokenAxios from "../../../src/apis/index";
import {
  ADD_RECIPE,
  ADD_RECIPE_SUCCESS,
  ADD_RECIPE_FAIL,
  GET_RECIPES,
  GET_RECIPES_SUCCESS,
  GET_RECIPES_FAIL,
  DELETE_RECIPE,
  DELETE_RECIPE_SUCCESS,
  DELETE_RECIPE_FAIL,
  UPDATE_RECIPE,
  GET_SINGLE_RECIPES,
} from "../../store/Recipe/actionTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addRecipeFail,
  addRecipeSuccess,
  getRecipesFail,
  getSingleRecipesSuccess,
  getRecipesSuccess,
  deleteRecipeFail,
  deleteRecipeSuccess,
  updateRecipeFail,
  updateRecipeSuccess,
  updateRecipe,
  getSingleRecipesFail,
} from "../../store/Recipe/actions";

function* addRecipeSaga(action) {
  try {
    const accessToken = sessionStorage.getItem("accessToken"); // Adjust as needed
    const response = yield call(axios.post, "recipe/add", action.payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    yield put(addRecipeSuccess(response.data));
    toast.success("Recipe added successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addRecipeFail(error));
    toast.error("Failed to add recipe", { autoClose: 2000 });
  }
}

// function* getRecipesSaga() {
//   try {
//     // Assuming you have a token stored in localStorage or Redux state

//     // Define headers with the token
//     const headers = {
//       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MTAzNjg1NjB9.1tVsu2OrTJjbtPwkRIC13bQy5aAKhwHjMVGfP_uvquI`, // Assuming your token requires a Bearer prefix
//     };

//     // Make the Axios request with the provided headers
//     // const response = yield call(axiosWithoutToken.post, "/admin/login", {
//     //   email: user.email,
//     //   password: user.password,
//     // });
//     const response = yield call(withTokenAxios.get, "/admin/recipe/list");

//     // Dispatch success action with response data

//     const value = response.data.recipes;

//     yield put(getRecipesSuccess(response.data));
//     return value;
//   } catch (error) {
//     // Dispatch failure action with error
//     yield put(getRecipesFail(error));
//     // Display error message to the user
//     //toast.error("Failed to fetch recipes", { autoClose: 2000 });
//   }
// }

// Applying pagination
function* getRecipesSaga(action) {
  const { limit, page } = action.payload;
  try {
    const headers = {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MTAzNjg1NjB9.1tVsu2OrTJjbtPwkRIC13bQy5aAKhwHjMVGfP_uvquI`, // Replace YOUR_TOKEN with your actual token or a variable that holds the token
    };

    const response = yield call(
      withTokenAxios.get,
      `/admin/recipe/list?limit=${limit}&page=${page}`,
      { headers }
    );
    yield put(getRecipesSuccess(response.data));
  } catch (error) {
    yield put(getRecipesFail(error));
  }
}

export function* watchGetRecipes() {
  yield takeLatest("GET_RECIPES", getRecipesSaga);
}

function* deleteRecipeSaga(action) {
  try {
    const response = yield call(
      axios.delete,
      `https://api.mykilo.co.uk/admin/delete/recipe/${action.payload}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MTAzNjg1NjB9.1tVsu2OrTJjbtPwkRIC13bQy5aAKhwHjMVGfP_uvquI`,
        },
      }
    );

    // Log the response data for debugging

    if (response.status === 200) {
      yield put(deleteRecipeSuccess(action.payload));
      toast.success("Recipe deleted successfully", { autoClose: 2000 });
    } else {
      // Handle unexpected status codes here
      yield put(deleteRecipeFail("Unexpected status code: " + response.status));
      toast.error("Failed to delete recipe", { autoClose: 2000 });
    }
  } catch (error) {
    // Log any caught errors for debugging
    console.error("Delete Recipe Error:", error);
    yield put(deleteRecipeFail(error));
    toast.error("Failed to delete recipe", { autoClose: 2000 });
  }
}

function* updateRecipeSaga(action) {
  try {
    const accessToken = sessionStorage.getItem("accessToken"); // Adjust as needed
    const response = yield call(
      axios.put,
      `recipe/update/${action.payload.id}`,
      action.payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield put(updateRecipeSuccess(response.data));
    toast.success("Recipe updated successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(updateRecipeFail(error));
    toast.error("Failed to update recipe", { autoClose: 2000 });
  }
}

function* getSingleRecipeSaga(action) {
  try {
    const response = yield call(
      axios.get,
      `https://api.mykilo.co.uk/admin/recipe/${action.payload}`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MTAzNjg1NjB9.1tVsu2OrTJjbtPwkRIC13bQy5aAKhwHjMVGfP_uvquI`,
        },
      }
    );

    yield put(getSingleRecipesSuccess(response.data));
    //toast.success("Recipe get successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(getSingleRecipesFail(error));
    toast.error("Failed to get recipe", { autoClose: 2000 });
  }
}
function* recipeSaga() {
  yield takeEvery(ADD_RECIPE, addRecipeSaga);
  yield takeEvery(GET_SINGLE_RECIPES, getSingleRecipeSaga);

  yield takeEvery(GET_RECIPES, getRecipesSaga);
  yield takeEvery(DELETE_RECIPE, deleteRecipeSaga);
  yield takeEvery(UPDATE_RECIPE, updateRecipeSaga);
}

export default recipeSaga;

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const successToster = (
  message = "Successfully",
  position = "top-right"
) => {
  toast.success(`${message}`, {
    position: position,
  });
};

export const errorToster = (message = "error", position = "top-right") => {
  toast.error(`${message}`, {
    position: position,
  });
};

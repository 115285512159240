import React from "react";

const UpperSection = ({ pagename, lastUpdated, title }) => {
  return (
    <>
      <div class="border-bottom">
        <div class="container mx-auto px-4 pt-8 pb-24">
          <div class="pagename">
            <h2 class="text-#303033 text-14px font-semibold">{pagename}</h2>
          </div>
          <div class="updates">
            <h2 class="text-#868293 text-14px font-medium mt-10">
              Last Updated: {lastUpdated}
            </h2>
          </div>
          <div class="title">
            <h2 class="text-40px text-#303033 font-semibold mt-3">{title}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpperSection;

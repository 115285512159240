import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "reactstrap";
import { ToastContainer } from "react-toastify";
import {
  getWorkOutVideoByIdApiHandler,
  upDateWorkOutVideoHandler,
} from "../../apis/workout-video-api";
import { NEXT_PUBLIC_BASE_URL } from "../../apis/index";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  videoTypeEnum,
  preferenceEnum,
  WorkoutTypeEnum,
  equipmentTypeEnum,
  durationTypeEnum,
  VideoCategoriesEnum,
} from "../../utils/recipe-constant";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Spinners from "../../components/Common/Spinner";

import * as Yup from "yup";
import { useFormik } from "formik";

const UpdatedWorkOutVideo = ({ heading1, title }) => {
  //meta title
  document.title = "mykilo";
  const navigation = useNavigate();
  const { id: paramId } = useParams();
  const [loading, setLoading] = useState(false);

  const [recipe, setrecipe] = useState([]);
  const [video, setVideo] = useState(null);
  const [durationValue, setDurationValue] = useState(null);
  const [workOutValue, setWorkOutValue] = useState(null);
  const [VideoCategoriesValue, setVideoCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [initialValues, setInitialValues] = useState({
    focus_type: [],
    video_path: "",
    video_title: "",
    workout_type: [],
    preference: [],
    equipment: [],

    duration: "",
    VideoCategories: [],
  });

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  };
  useEffect(() => {
    const fetchDatabyParams = async () => {
      setLoading(true);
      try {
        const response = await getWorkOutVideoByIdApiHandler(paramId);
        const video = response?.video;

        setInitialValues({
          equipment: video?.equipment?.map((equipment) => ({
            label: equipment?.replace(/_/g, " "),
            value: equipment,
          })),
          focus_type: video?.focus_type?.map((focus_type) => ({
            label: focus_type?.replace(/_/g, " "),
            value: focus_type,
          })),
          preference: video?.preference?.map((preference) => ({
            label: preference?.replace(/_/g, " "),
            value: preference,
          })),
          workout_type: video?.workout_type?.map((workout_type) => ({
            label: workout_type?.replace(/_/g, " "),
            value: workout_type,
          })),
          VideoCategories:
            video?.VideoCategories?.map((VideoCategories) => ({
              label: VideoCategories?.replace(/_/g, " "),
              value: VideoCategories,
            })) || [],
          description: video?.description || "",
          duration: video?.duration || "",
          video_path: video?.video_path || "",
          video_title: video?.video_title || "",
        });
        const durationObject = {
          label: video?.duration,
          value: video?.duration,
        };
        setDurationValue(durationObject);
        setLoading(false);
      } catch (error) {
        // navigation("/workout-videos");
        setLoading(false);
      }
    };
    fetchDatabyParams();
  }, [paramId]);

  // validation

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      const formData = {
        video_path: values.video_path,
        duration: values.duration,
        video_title: values.video_title,
        VideoCategories: appendArray(values.VideoCategories),
        workout_type: appendArray(values.workout_type),
        focus_type: appendArray(values.focus_type),
        preference: appendArray(values.preference),
        equipment: appendArray(values.equipment),
      };

      const response = await upDateWorkOutVideoHandler(
        formData,
        setIsLoading,
        paramId
      );
      if (response?.data?.success) {
        resetForm();
        navigation("/workout-videos");
      }
    },
  });
  if (loading) {
    return <Spinners />;
  }
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem={title} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{heading1}</CardTitle>
                  <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="video_title"
                        className="col-form-label col-lg-2"
                      >
                        Video Title
                      </Label>
                      <Col lg="10">
                        <Input
                          id="video_title"
                          name="video_title"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.video_title || ""}
                        /> 
                        {validation.touched.video_title &&
                        validation.errors.video_title ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.video_title}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="video_title"
                        className="col-form-label col-lg-2"
                      >
                        Video Link
                      </Label>
                      <Col lg="10">
                        <Input
                          id="video_path"
                          name="video_path"
                          type="text"
                          className="form-control"
                          placeholder="Add video link..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.video_path || ""}
                        />
                        {validation.touched.video_path &&
                        validation.errors.video_path ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.video_path}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Video Type
                      </Label>
                      <Col lg={10}>
                        <Select
                          isMulti
                          id="focus_type"
                          name="focus_type"
                          value={validation?.values?.focus_type}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            const updatedFocusTypes = [
                              ...validation.values.focus_type,
                              selectedOptions.value,
                            ];

                            validation.setFieldValue(
                              "focus_type",
                              selectedOptions
                            );
                            validation.handleBlur("focus_type");
                          }}
                          options={videoTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.focus_type &&
                          validation.errors.focus_type && (
                            <div className="text-danger">
                              {validation.errors.focus_type}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Workout Type
                      </Label>

                      <Col lg={10}>
                        <Select
                          isMulti
                          id="workout_type"
                          name="workout_type"
                          value={validation?.values?.workout_type}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "workout_type",
                              selectedOptions
                            );
                            validation.handleBlur("workout_type");
                          }}
                          options={WorkoutTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.workout_type &&
                          validation.errors.workout_type && (
                            <div className="text-danger">
                              {validation.errors.workout_type}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Preference
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="preference"
                          name="preference"
                          isMulti 
                          value={validation?.values?.preference}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "preference",
                              selectedOptions
                            );
                            validation.handleBlur("preference");
                          }}
                          options={preferenceEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.preference &&
                          validation.errors.preference && (
                            <div className="text-danger">
                              {validation.errors.preference}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Equipment
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="equipment"
                          name="equipment"
                          isMulti
                          value={validation?.values?.equipment}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "equipment",
                              selectedOptions
                            );
                            validation.handleBlur("equipment");
                          }}
                          options={equipmentTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.equipment &&
                          validation.errors.equipment && (
                            <div className="text-danger">
                              {validation.errors.equipment}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Duration
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="duration"
                          name="duration"
                          value={durationValue}
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            setDurationValue(selectedOption);
                            validation.setFieldValue(
                              "duration",
                              selectedOption?.value
                            );
                            validation.handleBlur("duration");
                          }}
                          options={durationTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, "-"),
                          }))}
                        />
                        {validation.touched.duration &&
                          validation.errors.duration && (
                            <div className="text-danger">
                              {validation.errors.duration}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Video Categories
                      </Label>
                      <Col lg={10}>
                        <Select
                          isMulti
                          id="VideoCategories"
                          name="VideoCategories"
                          value={validation?.values?.VideoCategories}
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            validation.setFieldValue(
                              "VideoCategories",
                              selectedOption
                            );
                            validation.handleBlur("VideoCategories");
                          }}
                          options={VideoCategoriesEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, "-"),
                          }))}
                        />
                        {validation.touched.VideoCategories &&
                          validation.errors.VideoCategories && (
                            <div className="text-danger">
                              {validation.errors.VideoCategories}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <div className=" d-flex  justify-content-end  ">
                      <Button
                        type="submit"
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        disabled={isLoading ? true : false}
                      >
                        {isLoading ? " Updating video" : " Update video"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </>
  );
};

export default UpdatedWorkOutVideo;

function appendArray(array) {
  let updateArray = [];
  array?.length > 0 && array?.map((item) => updateArray.push(item?.value));
  return updateArray;
}

// Function to convert image to binary format
const convertImageToBinary = async (image) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(image);
  });
};

const convertCoverImageToBinaryIfRequired = async (thumbnail) => {
  if (thumbnail instanceof File && thumbnail.type.startsWith("image/")) {
    // If cover image is not already in binary format, convert it
    if (
      !thumbnail.type.includes("image/jpeg") &&
      !thumbnail.type.includes("image/png")
    ) {
      convertImageToBinary(thumbnail);
    }
  }
  return thumbnail;
};

const validationSchema = Yup.object().shape({
  focus_type: Yup.array().min(1, "Select at least one focus type"),
  workout_type: Yup.array().min(1, "Select at least one workout type"),
  video_path: Yup.string().required("Video link is required"),
  video_title: Yup.string().required("Video title is required"),
  preference: Yup.array().min(1, "Select at least one preference"),
  equipment: Yup.array().min(1, "Select at least one equipment"),
  duration: Yup.string().required("Duration is required"),
  VideoCategories: Yup.array().min(1, "Select at least one Video Categories"),
});

const handleCoverImageChange = (files) => {
  const file = files[0];
  setCoverImage(file);
  validation.setFieldValue("thumbnail", file);
  setDefaultImageUrl(null);
};

{
  /*
      <Row>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="coverImage"
                            className="col-form-label col-lg-2"
                          >
                            Thumbnail Image
                          </Label>
                          <Col lg="10">
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                handleCoverImageChange(acceptedFiles)
                              }
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div>
                                  <div
                                    className="dropzone d-flex justify-content-center align-items-center"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {defaultImageUrl && (
                                      <div className="border image-preview-box mt-2 position-relative">
                                        <img
                                          src={defaultImageUrl}
                                          alt="Default Image"
                                          style={{
                                            maxHeight: "220px",
                                            maxWidth: "220px",
                                          }}
                                        />

                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm position-absolute top-0 end-0 border"
                                          style={{ zIndex: 999 }}
                                          onClick={() => {
                                            setDefaultImageUrl(null);
                                          }}
                                        >
                                          X
                                        </button>
                                      </div>
                                    )}
                                    {/* Render delete button only if cover image is present 
                                    {coverImage && (
                                      <div className="image-preview-box mt-2 position-relative">
                                        <img
                                          src={URL?.createObjectURL(coverImage)}
                                          alt="Cover Image Preview"
                                          style={{
                                            maxHeight: "220px",
                                            maxWidth: "220px",
                                          }}
                                        />
                                        
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                          style={{ zIndex: 1 }}
                                          onClick={() => setCoverImage(null)}
                                        >
                                          X
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </Col>
                        </FormGroup>
                      </Row>
*/
}

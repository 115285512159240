import React from "react";

import UpperSection from "../privacy-policy/upper-section";
import PageContent from "../privacy-policy/content-section";

const TermsAndConditionComponent = () => {
  const contentArray = [
    {
      id: "content1",
      heading: "1. Agreement To Terms",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content2",
      heading: "2. Intellectual Property Rights",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content3",
      heading: "3. User Representations",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content4",
      heading: "4. User Registration",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content5",
      heading: "5. Fees And Payment",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content6",
      heading: "6. Pricing Changes",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content7",
      heading: "7. Subscription Cancelation & Refunds",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content8",
      heading: "8. Prohibited Activities",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content9",
      heading: "9. User Generated Contributions",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content10",
      heading: "10. Contribution License",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content11",
      heading: "11. Social Media",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content12",
      heading: "12. Submissions",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content13",
      heading: "13. Third-Party Website Content",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content14",
      heading: "14. Advertisers",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content15",
      heading: "15. Site Management",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content16",
      heading: "16. Privacy Policy",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content17",
      heading: "17. Copyright Infrigements",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content18",
      heading: "18. Term And Termination",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
    {
      id: "content19",
      heading: "19. Modifications And Interruptions",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vehicula turpis nec tellus fermentum, vel commodo libero tincidunt. Vestibulum et mauris a metus vehicula ultrices. Sed posuere justo et mi tincidunt, ac varius odio tristique. Proin fermentum tellus eget odio venenatis, vel fringilla ligula feugiat. Fusce convallis, ipsum a luctus laoreet, felis lectus bibendum odio, nec facilisis quam nisl vel metus. Maecenas vel urna et mi fringilla varius nec sed nisl. Integer sagittis, dui id condimentum sodales, justo lacus mattis lectus, nec fermentum mauris turpis at nisi. Nullam in justo eu sapien auctor feugiat. Suspendisse potenti. Integer eu neque sit amet mi tristique tristique.",
    },
  ];
  return (
    <div>
      <UpperSection
        pagename="Terms And Conditions"
        lastUpdated="19 August 2022"
        title="WEBSITE Terms And Conditions"
      />
      <PageContent data={contentArray} />
    </div>
  );
};

export default TermsAndConditionComponent;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Spinners from "components/Common/Spinner";
import Paginations from "components/Common/Pagination";

const API_BASE_URL = 'https://api.mykilo.co.uk/admin/'; // Replace with your actual API URL
const AUTH_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"; // Replace with your actual token

const fetchReviews = async (page, limit) => {
  const response = await axios.get(`${API_BASE_URL}/reviews?page=${page}&limit=${limit}`, {
    headers: {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    },
  });
  return response.data;
};

const updateReviewStatus = async (id, status) => {
  const response = await axios.put(`${API_BASE_URL}/reviews/${id}`, { status }, {
    headers: {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    },
  });
  return response.data;
};

const deleteReview = async (id) => {
  const response = await axios.delete(`${API_BASE_URL}/reviews/${id}`, {
    headers: {
      Authorization: `Bearer ${AUTH_TOKEN}`,
    },
  });
  return response.data;
};

const ProductReviews = () => {
  document.title = "Product Reviews";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [totalReviews, setTotalReviews] = useState(0);
  const totalPages = Math.ceil(totalReviews / perPageData);

  const fetchData = async (currentPage) => {
    setLoading(true);
    try {
      const data = await fetchReviews(currentPage, perPageData);
      setTotalReviews(data.totalPages * perPageData); // This may need adjustment based on your actual data structure
      setReviews(data.reviews);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleUpdateReviewStatus = async (id, status) => {
    try {
      setLoading(true);
      await updateReviewStatus(id, status);
      fetchData(currentPage);
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: `Review ${status} successfully!`,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error updating review status",
        text: error.message,
        showConfirmButton: true,
      });
    }
  };

  const handleDeleteReview = async (id) => {
    try {
      setLoading(true);
      await deleteReview(id);
      fetchData(currentPage);
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: `Review deleted successfully!`,
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error deleting review",
        text: error.message,
        showConfirmButton: true,
      });
    }
  };

  if (loading) {
    return <Spinners />;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2">
                    <h5>Product Reviews</h5>
                  </div>
                </Col>
              </Row>

              <Row>
                {reviews && reviews.length > 0 ? (
                  reviews.map((review, key) => (
                    <Col xl={4} sm={6} key={"_col_" + key}>
                      <Card>
                        <CardBody className="p-4" style={{ borderRadius: "10%", height: "300px" }}>
                          <div className="recipe-img position-relative">
                            <div className="mt-4 text-center">
                              <h5 className="mb-3 text-truncate font-weight-bold">
                                {review.productname}
                              </h5>
                              <hr className="w-25 mx-auto mb-3" style={{ borderTop: "2px solid #ddd" }} />
                              <p className="text-muted" style={{ overflow: "auto", maxHeight: "80px" }}>
                                {review.review}
                              </p>
                              <p className="text-muted">
                                <strong>Rating:</strong> {review.rating}
                              </p>
                            </div>
                          </div>
                          <div>
                            <strong>({review.status})</strong>
                          </div>
                          <div className="d-flex justify-content-end gap-4 item-center smb-3 mr-4">
                            <Button
                              color="success"
                              onClick={() => handleUpdateReviewStatus(review._id, "approved")}
                              className="btn btn-success waves-effect waves-light"
                            >
                              Approve
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => handleUpdateReviewStatus(review._id, "rejected")}
                              className="btn btn-danger waves-effect waves-light"
                            >
                              Decline
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => handleDeleteReview(review._id)}
                              className="btn btn-danger waves-effect waves-light"
                            >
                              Delete
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <h4>No Reviews Found</h4>
                )}
              </Row>
              {totalPages > 1 && (
                <Paginations currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProductReviews.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default ProductReviews;

import { divIcon } from "leaflet";
import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

const OrderId = (cell) => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  );
};

const BillingName = (cell) => {
  return cell.value ? cell.value : "";
};

const Date = (cell) => {
  return cell.value ? cell.value : "";
};

const Total = (cell) => {
  return cell.value ? cell.value : "";
};
const OrderStatus2 = (cell) => {
  return (
    <Badge className={"font-size-12 badge-soft-danger"}>{"Pending"}</Badge>
  );
};
const CouponCode = (cell) => {
  return <Badge className={"font-size-12 "}>{"01234871"}</Badge>;
};
const TagBy = (cell) => {
  return (
    <Badge className={"font-size-12 badge-soft-danger"}>
      {"Ali"}
      {"Usama"}
    </Badge>
  );
};
const SubscriptionPrice = (cell) => {
  return <Badge className={"font-size-12 badge-soft-danger"}>{"100$"}</Badge>;
};
const Duration = (cell) => {
  return (
    <Badge className={"font-size-12 badge-soft-warning"}>{"2-3 Months"}</Badge>
  );
};
const CustomerReviews = (cell) => {
  return (
    <Badge className={"font-size-12 bg-light"}>
      {
        "Thank you for your purchase! If you enjoyed your shopping experience, tell us (and others) about it!” "
      }
    </Badge>
  );
};
const PaymentStatus = (cell) => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value === "Paid"
          ? "success"
          : "danger" && cell.value === "Refund"
          ? "warning"
          : "danger")
      }
    >
      {cell.value}
    </Badge>
  );
};
const PaymentMethod = (cell) => {
  return (
    <span>
      <i
        className={
          cell.value === "Paypal"
            ? "fab fa-cc-paypal me-1"
            : "" || cell.value === "COD"
            ? "fab fas fa-money-bill-alt me-1"
            : "" || cell.value === "Mastercard"
            ? "fab fa-cc-mastercard me-1"
            : "" || cell.value === "Visa"
            ? "fab fa-cc-visa me-1"
            : ""
        }
      />{" "}
      {cell.value}
    </span>
  );
};
const OrderStatus = (cell) => {
  switch (cell.value) {
    case "Completed":
      return <Badge className="bg-success font-size-10">Completed</Badge>;
    case "Pending":
      return <Badge className="bg-warning font-size-10">Pending</Badge>;
    case "Failed":
      return <Badge className="bg-danger font-size-10">Failed</Badge>;
  }
};
export {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  OrderStatus,
  OrderStatus2,
  CouponCode,
  TagBy,
  Duration,
  SubscriptionPrice,
  CustomerReviews,
};

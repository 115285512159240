import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addProduct,
  getProductById,
  updateProduct,
} from "../../store/products/actions";

const QuillField = ({ field, form }) => {
  const quillRef = React.useRef(null);

  React.useEffect(() => {
    if (quillRef.current && quillRef.current.editor) {
      quillRef.current.editor.root.setAttribute(
        "data-gramm",
        "false"
      ); /*eslint no-param-reassign:0*/
    }
  }, [quillRef]);

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      onBlur={() => form.setFieldTouched(field.name, true)}
      onChange={(value) => form.setFieldValue(field.name, value)}
      value={field.value || ""}
    />
  );
};

const FileDropzone = ({ handleAcceptedFiles, selectedFiles }) => {
  return (
    <>
      <Dropzone onDrop={handleAcceptedFiles}>
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dz-message needsclick">
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                </div>
                <h4>Drop files here or click to upload.</h4>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => (
          <Card
            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
            key={i + "-file"}
          >
            <div className="p-2">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    height="80"
                    className="avatar-sm rounded bg-light"
                    alt={f.name}
                    src={f.preview}
                  />
                </Col>
                <Col>
                  <Link to="#" className="text-muted font-weight-bold">
                    {f.name}
                  </Link>
                  <p className="mb-0">
                    <strong>{f.formattedSize}</strong>
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
};

const CustomFormGroup = ({
  id,
  name,
  type,
  label,
  placeholder,
  validation,
}) => (
  <FormGroup className="mb-4" row>
    <Label htmlFor={id} className="col-form-label col-lg-2">
      {label}
    </Label>
    <Col lg="10">
      <Input
        id={id}
        name={name}
        type={type}
        className="form-control"
        placeholder={placeholder}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[name] || ""}
        invalid={validation.touched[name] && validation.errors[name]}
      />
      {validation.touched[name] && validation.errors[name] && (
        <FormFeedback type="invalid">{validation.errors[name]}</FormFeedback>
      )}
    </Col>
  </FormGroup>
);

const ContentManagement = ({
  heading1,
  vname,
  Description,
  title,
  uploadbutton,
  attach,
}) => {
  document.title =
    "Create New Project | Mykilo - React Admin & Dashboard Template";

  const { id } = useParams(); // Assuming you have productId in URL params for edit
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [valuesToEdit, setValuesToEdit] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: valuesToEdit || {
      projectname: "",
       inTheBox: "",
     
        noteTitle: "",
        noteContent: "",
      
      projectdesc: "",
      price: "",
      productCategory: "",
      subCategory: "",
      productDetails: "",
    },
    validationSchema: Yup.object({
      projectname: Yup.string().required("Please Enter Your Project Name"),
      projectdesc: Yup.string().required(
        "Please Enter Your Project Description"
      ),
      inTheBox: Yup.string().required("Please Enter In the box details"),
    
        noteTitle: Yup.string().required("Please Enter Note Title"),
        noteContent: Yup.string().required("Please Enter Note Content"),
   
      price: Yup.string().required("Please Enter Your Price"),
      productCategory: Yup.string().required("Please Select a Category"),
      // subCategory: Yup.string().required("Please Select a Sub-Category"),
      productDetails: Yup.string().required("Please Enter Product Details"),
    }),
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("productName", values.projectname);
        formData.append("details", values.productDetails);
        formData.append("inTheBox", values.inTheBox);
        formData.append("price", values.price);
        formData.append("category", values.productCategory);
        formData.append("subCategory", values.subCategory);
        formData.append("description", values.projectdesc);
        formData.append("noteTitle", values.noteTitle);
        formData.append("noteContent", values.noteContent);
        selectedFiles.forEach((file) => {
          formData.append("images", file);
        });

        if (id) {
          // Update existing product
          const response = await fetch(
            `https://api.mykilo.co.uk/admin/update/product/${id}`,
            {
              method: "PUT",
              body: formData,
              headers: {
                Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
              },
            }
          );

          if (response.ok) {
            toast.success("Product updated successfully", { autoClose: 2000 });
            validation.resetForm();
            setSelectedFiles([]);
            navigate("/products");
          } else {
            toast.error("Failed to update product", { autoClose: 2000 });
          }
        } else {
          // Add new product
          const response = await fetch(
            "https://api.mykilo.co.uk/admin/upload/product",
            {
              method: "POST",
              body: formData,
              headers: {
                Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
              },
            }
          );

          if (response.ok) {
            toast.success("Product added successfully", { autoClose: 2000 });
            validation.resetForm();
            setSelectedFiles([]);
            navigate("/products");
          } else {
            toast.error("Failed to add product", { autoClose: 2000 });
          }
        }
      } catch (error) {
        toast.error("Failed to add/update product", { autoClose: 2000 });
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  useEffect(() => {
    // Fetch product data if editing existing product
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `https://api.mykilo.co.uk/admin/get/product/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();

          setValuesToEdit({
            projectname: data.data.productName,
            productDetails: data.data.details,
            projectdesc: data.data.description,
            price: data.data.price,
            productCategory: data.data.category,
            subCategory: data.data.subCategory,
            inTheBox: data.data.inTheBox,
            noteTitle: data.data.noteTitle,
            noteContent: data.data.noteContent,
          });
          setSelectedCategory(data.category);
        } else {
          toast.error("Failed to fetch product data", { autoClose: 2000 });
        }
      } catch (error) {
        toast.error("Failed to fetch product data", { autoClose: 2000 });
      }
    };

    if (id) {
      fetchProduct();
    }
  }, [id]);
  const handleAcceptedFiles = (files) => {
    files.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
    });
    setSelectedFiles(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setSelectedCategory(value);
    validation.setFieldValue("productCategory", value);
    validation.setFieldValue("subCategory", "");
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Breadcrumbs title="Projects" breadcrumbItem={title} />
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <CardTitle className="mb-4" style={{ fontSize: "22px" }}>
                  {heading1}
                </CardTitle>
                <Form onSubmit={validation.handleSubmit}>
                  <CustomFormGroup
                    id="projectname"
                    name="projectname"
                    type="text"
                    label={vname}
                    placeholder={vname}
                    validation={validation}
                  />
                  <CustomFormGroup
                    id="projectdesc"
                    name="projectdesc"
                    type="textarea"
                    label={Description}
                    placeholder={Description}
                    validation={validation}
                  />
                   <CustomFormGroup
                    id="inTheBox"
                    name="inTheBox"
                    type="text"
                    label={"In The Box"}
                    placeholder={"In The Box"}
                    validation={validation}
                  />

                 <CustomFormGroup
                    id="noteTitle"
                    name="noteTitle"
                    type="text"
                    label="Note Title"
                    placeholder="Enter Note Title"
                    validation={validation}
                  />

                  <CustomFormGroup
                    id="noteContent"
                    name="noteContent"
                    type="textarea"
                    label="Note Content"
                    placeholder="Enter Note Content"
                    validation={validation}
                  />
        
                  <CustomFormGroup
                    id="price"
                    name="price"
                    type="text"
                    label="Price"
                    placeholder="Price"
                    validation={validation}
                  />
                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="productCategory"
                      className="col-form-label col-lg-2"
                    >
                      Product Category
                    </Label>
                    <Col lg="10">
                      <Input
                        id="productCategory"
                        name="productCategory"
                        type="select"
                        className="form-control"
                        onChange={handleCategoryChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.productCategory || ""}
                        invalid={
                          validation.touched.productCategory &&
                          validation.errors.productCategory
                        }
                      >
                        <option value="">Select Category</option>
                        <option value="aquaBags">Aqua Bags</option>
                        <option value="dietPlans">Diet Plans</option>
                        <option value="workoutPlans">Workout Plans</option>
                      </Input>
                      {validation.touched.productCategory &&
                        validation.errors.productCategory && (
                          <FormFeedback type="invalid">
                            {validation.errors.productCategory}
                          </FormFeedback>
                        )}
                    </Col>
                  </FormGroup>
                  {selectedCategory === "aquaBags" && (
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="subCategory"
                        className="col-form-label col-lg-2"
                      >
                        Sub-Category
                      </Label>
                      <Col lg="10">
                        <Input
                          id="subCategory"
                          name="subCategory"
                          type="select"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.subCategory || ""}
                          invalid={
                            validation.touched.subCategory &&
                            validation.errors.subCategory
                          }
                        >
                          <option value="">Select Sub-Category</option>
                          <option value="kiloBuster">KiloBuster</option>
                          <option value="kiloBubble">KiloBubble</option>
                        </Input>
                        {validation.touched.subCategory &&
                          validation.errors.subCategory && (
                            <FormFeedback type="invalid">
                              {validation.errors.subCategory}
                            </FormFeedback>
                          )}
                      </Col>
                    </FormGroup>
                  )}

                  {selectedCategory === "dietPlans" && (
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="subCategory"
                        className="col-form-label col-lg-2"
                      >
                        Sub-Category
                      </Label>
                      <Col lg="10">
                        <Input
                          id="subCategory"
                          name="subCategory"
                          type="select"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.subCategory || ""}
                          invalid={
                            validation.touched.subCategory &&
                            validation.errors.subCategory
                          }
                        >
                          <option value="">Select Sub-Category</option>
                          <option value="2week">2 Week</option>
                          <option value="4week">4 Week</option>
                          <option value="6week">6 Week</option>
                        </Input>
                        {validation.touched.subCategory &&
                          validation.errors.subCategory && (
                            <FormFeedback type="invalid">
                              {validation.errors.subCategory}
                            </FormFeedback>
                          )}
                      </Col>
                    </FormGroup>
                  )}

                  <FormGroup className="mb-4" row>
                    <Label
                      htmlFor="productDetails"
                      className="col-form-label col-lg-2"
                    >
                      Product Details
                    </Label>
                    <Col lg="10">
                      <QuillField
                        name="productDetails"
                        field={validation.getFieldProps("productDetails")}
                        form={validation}
                      />
                      {validation.touched.productDetails &&
                        validation.errors.productDetails && (
                          <div className="invalid-feedback">
                            {validation.errors.productDetails}
                          </div>
                        )}
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">{attach}</Label>
                    <Col lg="10">
                      <FileDropzone
                        handleAcceptedFiles={handleAcceptedFiles}
                        selectedFiles={selectedFiles}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-0" row>
                    <Col lg={{ size: 10, offset: 2 }}>
                      <Button
                        type="submit"
                        style={{ backgroundColor: "#9C3022" }}
                        disabled={isSubmitting}
                      >
                        {uploadbutton}
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContentManagement;

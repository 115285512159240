import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CustomerSupport = ({ heading1, title, vname, Description }) => {
  // Define and initialize formData state
  const [formData, setFormData] = useState({
    username: "",
    description: "",
  });

  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Here you can perform actions with formData, like dispatching it to redux store or sending it to the server
  };

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Projects" breadcrumbItem={vname} />
        <Row>
          <Col lg="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{heading1}</CardTitle>
                <div className="d-flex flex-column ">
                  <div className="d-flex">
                    <div className="align-self-start me-3">
                      <img
                        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    </div>
                    <div className="align-self-center flex-grow-1 overflow-hidden">
                      <h5
                        className="text-truncate  font-size-16 mb-1"
                        style={{ color: "#303033" }}
                      >
                        Adam Miller
                      </h5>
                      <Label>{title}</Label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="align-self-start me-3">
                      <img
                        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    </div>
                    <div className="align-self-center flex-grow-1 overflow-hidden">
                      <h5 className="text-truncate font-size-16 mb-1">
                        Adam Miller
                      </h5>
                      <Label>{title}</Label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="align-self-start me-3">
                      <img
                        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    </div>
                    <div className="align-self-center flex-grow-1 overflow-hidden">
                      <h5 className="text-truncate font-size-16 mb-1">
                        Adam Miller
                      </h5>
                      <Label>{title}</Label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="align-self-start me-3">
                      <img
                        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    </div>
                    <div className="align-self-center flex-grow-1 overflow-hidden">
                      <h5 className="text-truncate font-size-16 mb-1">
                        Adam Miller
                      </h5>
                      <Label>{title}</Label>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card>
              <CardBody>
                {/* <h4 className="card-title">Add New Coupon</h4>y */}
                <Form onSubmit={handleFormSubmit}>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <h5 className="text-truncate text-center font-size-20 mb-1">
                            Adam Miller
                          </h5>
                          <Label className="text-truncate text-center font-size-16">
                            {title}
                          </Label>
                        </div>
                        <label>Description</label>
                        <Input
                          type="textarea"
                          name="description"
                          id="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          placeholder="Enter description"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomerSupport;

{
  /* <CardBody>
<CardTitle className="mb-4">{heading1}</CardTitle>
<div className="d-flex flex-column ">
  <div className="d-flex">
    <div className="align-self-start me-3">
      <img
        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
        className="rounded-circle avatar-xs"
        alt=""
      />
    </div>
    <div className="align-self-center flex-grow-1 overflow-hidden">
      <h5
        className="text-truncate  font-size-16 mb-1"
        style={{ color: "#303033" }}
      >
        Adam Miller
      </h5>
      <Label>{title}</Label>
    </div>
  </div>
  <div className="d-flex">
    <div className="align-self-start me-3">
      <img
        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
        className="rounded-circle avatar-xs"
        alt=""
      />
    </div>
    <div className="align-self-center flex-grow-1 overflow-hidden">
      <h5 className="text-truncate font-size-16 mb-1">
        Adam Miller
      </h5>
      <Label>{title}</Label>
    </div>
  </div>
  <div className="d-flex">
    <div className="align-self-start me-3">
      <img
        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
        className="rounded-circle avatar-xs"
        alt=""
      />
    </div>
    <div className="align-self-center flex-grow-1 overflow-hidden">
      <h5 className="text-truncate font-size-16 mb-1">
        Adam Miller
      </h5>
      <Label>{title}</Label>
    </div>
  </div>
  <div className="d-flex">
    <div className="align-self-start me-3">
      <img
        src="/static/media/avatar-3.2cfd5ba6f0dee8d1d076.jpg"
        className="rounded-circle avatar-xs"
        alt=""
      />
    </div>
    <div className="align-self-center flex-grow-1 overflow-hidden">
      <h5 className="text-truncate font-size-16 mb-1">
        Adam Miller
      </h5>
      <Label>{title}</Label>
    </div>
  </div>
</div>
</CardBody> */
}

import React from "react";
import UploadUpdateRecipe from "./upload-update-recipe";
import UploadRecipeVideo from "./upload-recipe-video";

const ProjectsCreate = () => {
  return (
    <>
      {/* <UploadRecipeVideo /> */}
      <UploadUpdateRecipe />
    </>
  );
};

export default ProjectsCreate;

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import axios from "../../../src/apis/index";
import axios from "../../../src/apis/index";
import withTokenAxios from "../../../src/apis/index";
import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAIL,
} from "./actionTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addProductFail,
  addProductSuccess,
  getProductsFail,
  getSingleProductSuccess,
  getProductsSuccess,
  deleteProductFail,
  deleteProductSuccess,
  updateProductFail,
  updateProductSuccess,
  getSingleProductFail,
} from "./actions";

function* addProductSaga(action) {
  try {
    const accessToken = sessionStorage.getItem("accessToken"); // Adjust as needed
    const response = yield call(
      axios.post,
      "/admin/upload/product",
      action.payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    yield put(addProductSuccess(response.data));
    toast.success("Product added successfully", { autoClose: 2000 });
  } catch (error) {
    yield put(addProductFail(error));
    toast.error("Failed to add product", { autoClose: 2000 });
  }
}

function* getProductsSaga(action) {
  const { limit, page } = action.payload;
  try {
    const headers = {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MTAzNjg1NjB9.1tVsu2OrTJjbtPwkRIC13bQy5aAKhwHjMVGfP_uvquI`, // Replace YOUR_TOKEN with your actual token or a variable that holds the token
    };

    const response = yield call(
      axios.get,
      `/admin/get/products/?limit=${limit}&page=${page}`,
      { headers }
    );
    yield put(getProductsSuccess(response.data));
  } catch (error) {
    yield put(getProductsFail(error));
  }
}

// export function* watchGetProducts() {
//   yield takeLatest("GET_PRODUCTS", getProductsSaga);
// }

// function* deleteProductSaga(action) {
//   try {
//     const response = yield call(
//       axios.delete,
//       `https://api.mykilo.co.uk/admin/delete/product/${action.payload}`,
//       {
//         headers: {
//           Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MTAzNjg1NjB9.1tVsu2OrTJjbtPwkRIC13bQy5aAKhwHjMVGfP_uvquI`,
//         },
//       }
//     );

//     if (response.status === 200) {
//       yield put(deleteProductSuccess(action.payload));
//       toast.success("Product deleted successfully", { autoClose: 2000 });
//     } else {
//       yield put(
//         deleteProductFail("Unexpected status code: " + response.status)
//       );
//       toast.error("Failed to delete product", { autoClose: 2000 });
//     }
//   } catch (error) {
//     console.error("Delete Product Error:", error);
//     yield put(deleteProductFail(error));
//     toast.error("Failed to delete product", { autoClose: 2000 });
//   }
// }

// function* updateProductSaga(action) {
//   try {
//     const accessToken = sessionStorage.getItem("accessToken"); // Adjust as needed
//     const response = yield call(
//       axios.put,
//       `product/update/${action.payload.id}`,
//       action.payload,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${accessToken}`,
//         },
//       }
//     );

//     yield put(updateProductSuccess(response.data));
//     toast.success("Product updated successfully", { autoClose: 2000 });
//   } catch (error) {
//     yield put(updateProductFail(error));
//     toast.error("Failed to update product", { autoClose: 2000 });
//   }
// }

// function* getSingleProductSaga(action) {
//   try {
//     const response = yield call(
//       axios.get,
//       `https://api.mykilo.co.uk/admin/product/${action.payload}`,
//       {
//         headers: {
//           Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MTAzNjg1NjB9.1tVsu2OrTJjbtPwkRIC13bQy5aAKhwHjMVGfP_uvquI`,
//         },
//       }
//     );

//     yield put(getSingleProductSuccess(response.data));
//   } catch (error) {
//     yield put(getSingleProductFail(error));
//     toast.error("Failed to get product", { autoClose: 2000 });
//   }
// }

function* productSaga() {
  yield takeEvery(ADD_PRODUCT, addProductSaga);
  yield takeEvery(GET_PRODUCTS, getProductsSaga);
  // yield takeEvery(GET_SINGLE_PRODUCT, getSingleProductSaga);

  // yield takeEvery(DELETE_PRODUCT, deleteProductSaga);
  // yield takeEvery(UPDATE_PRODUCT, updateProductSaga);
}

export default productSaga;

import Axios from "axios";
// REACT_APP_API_BASE_URL=https://api.mykilo.co.uk
export const REACT_APP_AWS_BASE_URL =
  "https://mykilo.s3.eu-west-2.amazonaws.com";
export const NEXT_PUBLIC_BASE_URL = "https://api.mykilo.co.uk";
const axios = Axios.create({
  baseURL: NEXT_PUBLIC_BASE_URL,
});
export default axios;

axios.interceptors.request.use(
  async (config) => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const token = authUser?.content?.accessToken;
    

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosWithoutToken = Axios.create({
  // headers: {
  //   "Content-Type": "application/x-www-form-urlencoded",
  //   Accept: "application/json",
  // },
  baseURL: NEXT_PUBLIC_BASE_URL,
});

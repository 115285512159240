import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Paginations from "components/Common/Pagination";
import Spinners from "components/Common/Spinner";
import { getProducts, deleteProduct } from "store/products/actions";
import { ToastContainer, toast } from "react-toastify";

const Products = () => {
  document.title = "mykilo";

  const dispatch = useDispatch();

  const products = useSelector((state) => state?.ecommerce?.products?.data);
  const totalProducts = useSelector(
    (state) => state?.ecommerce?.products?.pagination?.totalProducts
  );
  const isLoading = useSelector((state) => state?.ecommerce?.products?.loading);
  const [loading, setLoading] = useState(isLoading);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const totalPages = Math.ceil(totalProducts / perPageData);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getProducts(perPageData, currentPage));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, perPageData, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(getProducts(perPageData, page));
  };

  const handleEdit = async (productId) => {
    try {
      // Example: Fetch product details for editing
      // Replace with your actual API endpoint and logic
      const response = await fetch(
        `https://api.mykilo.co.uk/admin/update/product/${productId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
          },
        }
      );

      if (response.ok) {
        const product = await response.json();
        // Redirect or handle product edit logic
        console.log("Product details fetched:", product);
        // Example: Redirect to edit page
        // history.push(`/edit-product/${productId}`);
      } else {
        console.error("Failed to fetch product details");
        toast.error("Failed to fetch product details", { autoClose: 2000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error fetching product details", { autoClose: 2000 });
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        
    
        const response = await fetch(
          `https://api.mykilo.co.uk/admin/delete/product/${productId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU3MmM4MWU0YmE2NDU0ODdkNWVlNTEiLCJpYXQiOjE3MDk5ODcyMzd9.KRYhQ22S17d73Q-4APQF2yWT-pgrV7bUYVzJbUG8Evo"}`,
            },
          }
        );

        if (response.ok) {
          console.log("Product deleted successfully");
          toast.success("Product deleted successfully", { autoClose: 2000 });
          // Example: Refresh products list after deletion
          dispatch(getProducts(perPageData, currentPage));
        } else {
          console.error("Failed to delete product");
          toast.error("Failed to delete product", { autoClose: 2000 });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to delete product", { autoClose: 2000 });
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2">
                    <h5>Products</h5>
                  </div>
                </Col>
              </Row>
              {loading ? (
                <Spinners setLoading={setLoading} />
              ) : products && products.length > 0 ? (
                <>
                  <Row>
                    {products.map((product, key) => (
                      <Col xl={4} sm={6} key={"_col_" + key}>
                        <div className="card-wrapper">
                          <Card>
                            <CardBody
                              className="p-4"
                              style={{
                                borderRadius: "10%",
                                height: "600px",
                              }}
                            >
                              <div className="recipe-img position-relative">
                                {product?.images[0] && (
                                  <>
                                    <img
                                      style={{
                                        height: "330px",
                                        borderRadius: "10px",
                                      }}
                                      src={`https://api.mykilo.co.uk/product/${product?.images[0]}`}
                                      alt=""
                                      className="img-fluid mx-auto d-block shadow-lg"
                                    />
                                  </>
                                )}
                              </div>
                              <div className="mt-4 text-center">
                                <h5 className="mb-3 text-truncate font-weight-bold">
                                  <Link
                                    to={`/product-details/${product?._id}`}
                                    className="text-dark text-decoration-none"
                                  >
                                    {product?.productName}
                                  </Link>
                                </h5>
                                <hr
                                  className="w-25 mx-auto mb-3"
                                  style={{ borderTop: "2px solid #ddd" }}
                                />
                                <p
                                  className="text-muted"
                                  style={{
                                    overflow: "auto",
                                    maxHeight: "160px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: product?.description,
                                  }}
                                ></p>
                              </div>
                            </CardBody>
                            <div
                              className="d-flex justify-content-end gap-4 mb-3 mr-4"
                              style={{ marginRight: "32px" }}
                            >
                              <Link
                                to={`/product/${product._id}/edit`}
                                color="secondary"
                                
                                className="btn btn-secondary waves-effect waves-light"
                              >
                                Edit
                              </Link>
                              <Button
                                color="danger"
                                onClick={() => handleDelete(product._id)}
                                className="btn btn-danger waves-effect waves-light"
                              >
                                Delete
                              </Button>
                            </div>
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Paginations
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={handlePageChange}
                    paginationDiv="col-lg-12"
                    paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
                  />
                </>
              ) : (
                <div className="text-center">
                  <h4>No product added yet</h4>
                </div>
              )}
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

Products.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(Products);

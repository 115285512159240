import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "reactstrap";
import { upLoadWorkOutVideoHandler } from "../../apis/workout-video-api";
import { ToastContainer } from "react-toastify";
import "./styles.css";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  videoTypeEnum,
  preferenceEnum,
  WorkoutTypeEnum,
  equipmentTypeEnum,
  durationTypeEnum,
  VideoCategoriesEnum,
} from "../../utils/recipe-constant";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import * as Yup from "yup";
import { useFormik } from "formik";

const UploadWorkOutVideo = ({ heading1, title }) => {
  //meta title
  document.title = "mykilo";
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2", // Set your desired background color here
    }),
  };

  const navigation = useNavigate();

  const [coverImage, setCoverImage] = useState(null);
  const [recipe, setrecipe] = useState([]);
  const [video, setVideo] = useState(null);
  const [durationValue, setDurationValue] = useState(null);
  const [workOutValue, setWorkOutValue] = useState(null);
  const [VideoCategoriesValue, setVideoCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedEducation, setSelectedEducation] = useState([]);

  const [initialValues, setInitialValues] = useState({
    thumbnail: null,
    focus_type: [],
    video_path: "",
    video_title: "",
    workout_type: [],
    preference: [],
    equipment: [],

    duration: "",
    VideoCategories: [],
  });

  function appendArrayWithIndex(formData, array, keyName) {
    array.forEach((item, index) => {
      formData.append(`${keyName}[${index}]`, item.value);
    });
  }

  const handleCoverImageChange = (files) => {
    const file = files[0];
    setCoverImage(file);
    validation.setFieldValue("thumbnail", file);
  };

  const convertCoverImageToBinaryIfRequired = async (thumbnail) => {
    if (thumbnail instanceof File && thumbnail.type.startsWith("image/")) {
      // If cover image is not already in binary format, convert it
      if (
        !thumbnail.type.includes("image/jpeg") &&
        !thumbnail.type.includes("image/png")
      ) {
        convertImageToBinary(thumbnail);
      }
    }
    return thumbnail;
  };

  // Function to convert image to binary format
  const convertImageToBinary = async (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
      thumbnail: Yup.mixed().required("Thumbnail is required"),
      focus_type: Yup.array().min(1, "Select at least one focus type"),
      workout_type: Yup.array().min(1, "Select at least one workout type  "),
      video_path: Yup.string().required("Video link is required"),
      video_title: Yup.string().required("Video title is required"),
      preference: Yup.array().min(1, "Select at least one preference"),
      equipment: Yup.array().min(1, "Select at least one equipment"),
      duration: Yup.string().required("Duration is required"),
      VideoCategories: Yup.array().min(
        1,
        " Select at least one Video Categories "
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("thumbnail", values.thumbnail);
      formData.append("video_path", values.video_path);

      formData.append("duration", values.duration);

      formData.append("video_title", values.video_title);

      appendArrayWithIndex(formData, values.VideoCategories, "VideoCategories");
      appendArrayWithIndex(formData, values.workout_type, "workout_type");
      appendArrayWithIndex(formData, values.focus_type, "focus_type");
      appendArrayWithIndex(formData, values.preference, "preference");
      appendArrayWithIndex(formData, values.equipment, "equipment");

      const response = await upLoadWorkOutVideoHandler(formData, setIsLoading);
      if (response?.data?.success) {
        resetForm();
        setVideoCategories(null);
        setDurationValue(null);
        setCoverImage(null);
        navigation("/workout-videos");
      }
    },
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem={title} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{heading1}</CardTitle>
                  <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="video_title"
                        className="col-form-label col-lg-2"
                      >
                        Video Tilte
                      </Label>
                      <Col lg="10">
                        <Input
                          id="video_title"
                          name="video_title"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.video_title || ""}
                        />
                        {validation.touched.video_title &&
                        validation.errors.video_title ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.video_title}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="video_title"
                        className="col-form-label col-lg-2"
                      >
                        Video Link
                      </Label>
                      <Col lg="10">
                        <Input
                          id="video_path"
                          name="video_path"
                          type="text"
                          className="form-control"
                          placeholder="Add video link..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.video_path || ""}
                        />
                        {validation.touched.video_path &&
                        validation.errors.video_path ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.video_path}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Video Type
                      </Label>
                      <Col lg={10}>
                        <Select
                          isMulti
                          id="focus_type"
                          name="focus_type"
                          value={validation?.values?.focus_type}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            const updatedFocusTypes = [
                              ...validation.values.focus_type,
                              selectedOptions.value,
                            ];

                            validation.setFieldValue(
                              "focus_type",
                              selectedOptions
                            );
                            validation.handleBlur("focus_type");
                          }}
                          options={videoTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.focus_type &&
                          validation.errors.focus_type && (
                            <div className="text-danger">
                              {validation.errors.focus_type}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Workout Type
                      </Label>

                      <Col lg={10}>
                        <Select
                          isMulti
                          id="workout_type"
                          name="workout_type"
                          value={validation?.values?.workout_type}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            setWorkOutValue(selectedOptions);
                            validation.setFieldValue(
                              "workout_type",
                              selectedOptions
                            );
                            validation.handleBlur("workout_type");
                          }}
                          options={WorkoutTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.workout_type &&
                          validation.errors.workout_type && (
                            <div className="text-danger">
                              {validation.errors.workout_type}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Preference
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="preference"
                          name="preference"
                          isMulti
                          value={validation?.values?.preference}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "preference",
                              selectedOptions
                            );
                            validation.handleBlur("preference");
                          }}
                          options={preferenceEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.preference &&
                          validation.errors.preference && (
                            <div className="text-danger">
                              {validation.errors.preference}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Equipment
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="equipment"
                          name="equipment"
                          isMulti
                          value={validation?.values?.equipment}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "equipment",
                              selectedOptions
                            );
                            validation.handleBlur("equipment");
                          }}
                          options={equipmentTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.equipment &&
                          validation.errors.equipment && (
                            <div className="text-danger">
                              {validation.errors.equipment}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Duration
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="duration"
                          name="duration"
                          value={durationValue}
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            setDurationValue(selectedOption);
                            validation.setFieldValue(
                              "duration",
                              selectedOption?.value
                            );
                            validation.handleBlur("duration");
                          }}
                          options={durationTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, "-"),
                          }))}
                        />
                        {validation.touched.duration &&
                          validation.errors.duration && (
                            <div className="text-danger">
                              {validation.errors.duration}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Video Categories
                      </Label>
                      <Col lg={10}>
                        <Select
                          isMulti
                          id="VideoCategories"
                          name="VideoCategories"
                          value={VideoCategoriesValue}
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            setVideoCategories(selectedOption);
                            validation.setFieldValue(
                              "VideoCategories",
                              selectedOption
                            );
                            validation.handleBlur("VideoCategories");
                          }}
                          options={VideoCategoriesEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, "-"),
                          }))}
                        />
                        {validation.touched.VideoCategories &&
                          validation.errors.VideoCategories && (
                            <div className="text-danger">
                              {validation.errors.VideoCategories}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="coverImage"
                          className="col-form-label col-lg-2"
                        >
                          Thumbnail Image
                        </Label>
                        <Col lg="10">
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleCoverImageChange(acceptedFiles)
                            }
                            accept="image/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div>
                                <div
                                  className="dropzone d-flex justify-content-center align-items-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  {!coverImage && (
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      {/*<h4>
                                        Drop files here or click to upload Size
                                        must be 1980 x 1320
                                      </h4>  */}
                                    </div>
                                  )}
                                  {coverImage && (
                                    <div className="image-preview-box mt-2 position-relative">
                                      <img
                                        src={URL?.createObjectURL(coverImage)}
                                        alt="Cover Image Preview"
                                        style={{
                                          maxHeight: "220px",
                                          maxWidth: "220px",
                                        }}
                                      />
                                      {/* Add delete button */}
                                      {coverImage && (
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                          style={{ zIndex: 1 }} // Ensure the button is above the image
                                          onClick={() => setCoverImage(null)} // This will delete the cover image
                                        >
                                          X
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="mt-2 d-flex justify-content-center align-items-center">
                                  <button
                                    type="button"
                                    style={{ backgroundColor: "#9c2f21" }}
                                    className="btn btn-primary"
                                    onClick={() => {
                                      // Trigger the file input click when the button is pressed
                                      document
                                        .querySelector('input[type="file"]')
                                        .click();
                                    }}
                                  >
                                    Upload Image
                                  </button>
                                </div>
                                {validation.touched.thumbnail &&
                                validation.errors.thumbnail ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation.errors.thumbnail}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            )}
                          </Dropzone>
                          {validation.touched.coverImage &&
                          validation.errors.coverImage ? (
                            <div className="text-danger mt-2">
                              {validation.errors.coverImage}
                            </div>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Row>

                    <div className=" d-flex  justify-content-end  ">
                      <Button
                        type="submit"
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        disabled={isLoading ? true : false}
                      >
                        {isLoading ? " uploading video" : " upload video"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UploadWorkOutVideo;

{
  /*
// useEffect(() => {
  //   if (id && Recipes) {
  //     // If id exists and Recipes data is available
  //     const notesString = Recipes.Notes.join("\n"); // Convert array of notes to string
  //     setCoverImage(Recipes?.recipe_Cover_picture);
  //     setrecipe(Recipes.recipe_pictures);

  //     setInitialValues({
  //       title: Recipes.recipe_title,
  //       addPoints: Recipes.addPoints, // Populate with data from Recipes
  //       description: Recipes.recipe_description,
  //       education: Recipes.dietary_restriction,
  //       coverImage: Recipes.recipe_Cover_picture,
  //       recipe_Categorie: "",
  //       courseCategories: [],
  //       convenienceCategories: [],
  //       preferenceCategories: [],
  //       recipe: Recipes.recipe_pictures,
  //       duration: "",
  //       servings: Recipes.servings,
  //       endDate: "",
  //       prepTime: Recipes.PREP_TIME,
  //       cookTime: Recipes.COOK_TIME,
  //       totalTime: Recipes.TOTAL_TIME,
  //       notes: notesString, // Set the notes as a string
  //       recipes: Recipes.recipes?.map((recipe) => ({
  //         recipeHeading: recipe.recipeHeading,
  //         ingredients: recipe.ingredients?.map((ingredient) => ({
  //           name: ingredient.name,
  //           quantity: ingredient.quantity,
  //         })),
  //       })),
  //     });
  //     if (Recipes && Recipes.dietary_restriction) {
  //       const initialSelectedEducation = Recipes?.dietary_restriction.map(
  //         (option) => ({
  //           value: option,
  //           label: displayEducationOptions[option],
  //         })
  //       );
  //       setSelectedEducation(initialSelectedEducation);
  //     }
  //     setrecipe(Recipes.recipe_pictures);
  //     setCoverImage(Recipes.recipe_Cover_picture);
  //   } else {
  //     // If id doesn't exist or Recipes data is not available
  //     setInitialValues({
  //       addPoints: [
  //         {
  //           Heading: "",
  //           details: [],
  //         },
  //       ],
  //       description: "",
  //       education: [],
  //       coverImage: null,
  //       recipe_Categorie: "",
  //       courseCategories: [],
  //       convenienceCategories: [],
  //       preferenceCategories: [],
  //       recipe: [],
  //       dietary_restriction: [],
  //       duration: "",
  //       servings: "",
  //       endDate: "",
  //       prepTime: "",
  //       cookTime: "",
  //       totalTime: "",
  //       notes: "",
  //       recipes: [
  //         {
  //           recipeHeading: "",
  //           ingredients: [
  //             {
  //               name: "",
  //               quantity: "",
  //             },
  //           ],
  //         },
  //       ],
  //     });
  //   }
  // }, [Recipes, id]);

  //use 
  
  const handleDeleteHeading = (index) => {
    const updatedAddPoints = [...validation.values.addPoints];
    updatedAddPoints.splice(index, 1);
    validation.setFieldValue("addPoints", updatedAddPoints);
    validation.setFieldTouched(`addPoints[${index}].Heading`, false);
  };
  


  const handleDeleteImage = (index) => {
    const updatedRecipe = [...recipe];
    updatedRecipe.splice(index, 1);
    setrecipe(updatedRecipe);
  };
  // Function to handle unchanged images
  // Modified handlerecipeChange function
  const handlerecipeChange = (files) => {
    // If no files are added, include unchanged images
    if (files.length === 0) {
      handleUnchangedImages();
      return;
    }

    setValue(1);

    // Append new files to the recipe state
    setrecipe((prevrecipe) => [...prevrecipe, ...files]);

    // Append new files to the form data
    validation.setFieldValue("recipe", [...recipe, ...files]);
  };

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Dispatch the saga function when the component mounts
        if (id) {
          dispatch(getSingleRecipes(id));
        }
      } catch (error) {
        // Handle error if needed
        console.error("Error fetching recipes:", error);
      }
    };

    fetchData();
  }, [id]);


  const [valuebool, setValue] = useState(0);
   const [present, setPresent] = useState(0);
   const [slectedCourse, setSelectedCourse] = useState([]);
   const [selectedFiles, setselectedFiles] = useState([]);
   const [selectedConvenience, setSelectedConvenience] = useState([]);
   const [selectedcategory, setSelectedcCategory] = useState([]);
   const [selectedPreference, setSelectedPreference] = useState([]);
   const [isNewCoverImage, setisNewCoverImage] = useState(0);



   const handleUnchangedImages = () => {
    // Append all existing images to the form data
    validation.setFieldValue("recipe", Recipes?.recipe_pictures);
  };
  
  const handleAddrecipe = () => {
    setrecipe([...recipe, ...newrecipe]);
    validation.setFieldValue("recipe", [...recipe, ...newrecipe]);
    setNewrecipe([]);
  };


  */
}

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Link, useParams, useNavigate } from "react-router-dom";
import { REACT_APP_AWS_BASE_URL } from "../../../apis/index";
import {
  getWorkOutProgramByIdApiHandler,
  uploadDaysWorkOurProgram,
  deleteScheduleApiHandler,
  updateScheduleApiHandler,
} from "../../../apis/workout-program-api";

import Spinners from "../../../components/Common/Spinner";
import Swal from "sweetalert2";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  CardSubtitle,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
const EditWorkoutSchedule = ({ title }) => {
  const { id: paramId } = useParams();
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);

  const [program, setProgram] = useState(null);
  const [restday, setRestDay] = useState(false);
  const [days, setDays] = useState([]);
  const [daySchedule, setDaySchedule] = useState({});

  const [optional, setOptional] = useState(false);

  const [selectedDay, setSelectedDay] = useState(1);
  const [inputs, setInputs] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    fetchDatabyParams(paramId, setLoading, setProgram, navigation);
  }, [paramId]);

  useEffect(() => {
    setDays(program?.schedule);
  }, [program]);

  console.log("program", program);

  useEffect(() => {
    const daySchedule = program?.schedule?.find(
      (item) => item.day_number === selectedDay
    );

    setDaySchedule(daySchedule);
  }, [days, selectedDay]);

  useEffect(() => {
    if (daySchedule?.videos) {
      setInputs(daySchedule?.videos);
      setRestDay(daySchedule?.restday);
    }
  }, [daySchedule]);

  // to handel input like title sub title and video
  const handleChange = (index, event, field) => {
    const { type, files, value, checked } = event.target;
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index] = {
        ...updatedInputs[index],
        [field]:
          type === "file" ? files[0] : type === "checkbox" ? checked : value,
      };

      return updatedInputs;
    });
  };
  // to handel input like mark as a optionl
  const handleCheckboxClick = (index, field) => {
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index] = {
        ...updatedInputs[index],
        [field]: !prevInputs[index][field],
      };
      return updatedInputs;
    });
  };

  const handleChangeVideo = (index) => {
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index].video = "";
      return updatedInputs;
    });
    const videoInput = document.getElementById(`videoInput-${index}`);
    videoInput.click();
  };

  const validateInputs = () => {
    let newErrors = {};

    if (!inputs.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!inputs.sub_title.trim()) {
      newErrors.sub_title = "Sub title is required";
    }
    if (!inputs.video) {
      newErrors.video = "Video is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSaveAndDelete = async (action, index) => {
    const itemToSave = inputs[index];

    if (action === "save") {
      setIsLoading(true);
      let updatedVideoData = new FormData(); // Ensure updatedVideoData is initialized here
      updatedVideoData.append("videoId", itemToSave?._id);
      updatedVideoData.append("day_number", selectedDay);
      updatedVideoData.append("title", itemToSave?.title);
      updatedVideoData.append("sub_title", itemToSave?.sub_title);
      updatedVideoData.append("optional", itemToSave?.optional);
      updatedVideoData.append("video", itemToSave?.video);
      updatedVideoData.append("restday", restday);

      const response = await updateScheduleApiHandler(
        updatedVideoData,
        paramId,
        setIsLoading
      );
      if (response?.success) {
        fetchDatabyParams(paramId, setLoading, setProgram, navigation);
      }
    } else if (action === "delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setDeleteLoading(true);
          // const formData = { videoId: itemToSave?._id };
          // updateAndDeleteScheduleAPiHadler(formData, "delete", paramId);
          deleteScheduleApiHandler(
            itemToSave?._id,
            paramId,
            setDeleteLoading
          ).then((response) => {
            if (response?.success) {
              fetchDatabyParams(paramId, setLoading, setProgram, navigation);
            }
          });
        }
      });
    }
  };
  const handleRestDay = () => {
    let updatedVideoData = new FormData();
    updatedVideoData.append("restday", restday);
    updatedVideoData.append("day_number", selectedDay);

    updateScheduleApiHandler(updatedVideoData, paramId, setIsLoading);
  };
  if (loading) {
    return <Spinners />;
  }
  console.log("input", inputs);
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Projects" breadcrumbItem={title} />
        <Card className="p-4">
          <CardTitle tag="h5">Tilte : {program?.program_title}</CardTitle>
          <CardSubtitle className="mb-2 mt-2 text-muted" tag="h6">
            Description : {program?.program_description}
          </CardSubtitle>
        </Card>
        {program?.schedule?.length > 0 && (
          <div className="d-flex justify-content-between">
            <div className="" style={{ width: "30%" }}>
              <CardTitle tag="h3">Select Day</CardTitle>
              <div
                className="d-flex flex-wrap p-2 rounded bg-white"
                style={{ border: "1px solid #9a2925" }}
              >
                {days?.map((day) => (
                  <p
                    className="rounded p-2 m-2"
                    key={day?._id}
                    style={{
                      border:
                        day?.day_number === selectedDay
                          ? "2px solid #9a2925"
                          : "1px solid black",
                      cursor: "pointer",
                      backgroundColor:
                        day?.day_number === selectedDay
                          ? "#9c2f21"
                          : "transparent",
                      color:
                        day?.day_number === selectedDay ? "#FFFFFF" : "#000000",
                    }}
                    onClick={() => setSelectedDay(day?.day_number)}
                  >
                    Day {day?.day_number}
                  </p>
                ))}
              </div>
            </div>
            <div className=" " style={{ width: "68%" }}>
              <CardTitle tag="h3">Day {selectedDay}</CardTitle>{" "}
              <div
                className="rounded p-2"
                style={{ border: "1px solid #9a2925" }}
              >
                <div className="form-check mr-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={restday}
                    onClick={() => setRestDay(!restday)}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Mark as rest day
                  </label>
                </div>
                {!restday &&
                  inputs?.map((input, index) => (
                    <div key={index} className="container mt-3 mb-3">
                      <div className="d-flex justify-content-between">
                        <div style={{ width: "40%" }}>
                          <input
                            className="p-2 rounded w-100"
                            name="title"
                            placeholder="Enter Title"
                            type="text"
                            value={input.title}
                            onChange={(event) =>
                              handleChange(index, event, "title")
                            }
                          />
                        </div>
                        <div style={{ width: "40%" }}>
                          <input
                            className="p-2 rounded w-100"
                            name="sub_title"
                            placeholder="Enter Sub Title"
                            type="text"
                            value={input.sub_title}
                            onChange={(event) =>
                              handleChange(index, event, "sub_title")
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="video-upload-container rounded bg-white"
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          height: "350px",
                          border: "1px solid black",
                          position: "relative",
                        }}
                      >
                        <label
                          htmlFor={`videoInput-${index}`}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            cursor: "pointer",
                          }}
                        >
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                          <h5 className="ml-3">Add your video</h5>
                        </label>
                        <input
                          id={`videoInput-${index}`}
                          name="video"
                          type="file"
                          accept="video/*"
                          onChange={(event) =>
                            handleChange(index, event, "video")
                          }
                          style={{ display: "none" }}
                        />
                        {input.video && (
                          <div
                            style={{
                              position: "relative",
                              zIndex: 1,
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <video
                              width="100%"
                              height="100%"
                              controls
                              style={{ position: "absolute", top: 0, left: 0 }}
                            >
                              <source
                                src={
                                  input.video instanceof File
                                    ? URL.createObjectURL(input.video)
                                    : `${REACT_APP_AWS_BASE_URL}/${input.video}`
                                }
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        )}
                      </div>
                      <div className="form-check mr-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={input.optional}
                          onClick={() => handleCheckboxClick(index, "optional")}
                          id={`flexCheckDefault-${index}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexCheckDefault-${index}`}
                        >
                          Mark as Optional
                        </label>
                      </div>
                      <div className="d-flex justify-content-end gap-3 mt-3 mb-3">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{}}
                          onClick={() => handleChangeVideo(index)}
                        >
                          Change Video
                        </button>
                        <button
                          type="button"
                          style={{ backgroundColor: "#9c2f21" }}
                          className="btn btn-primary"
                          onClick={() => handleSaveAndDelete("delete", index)}
                          disabled={deleteLoading}
                        >
                          {deleteLoading ? "Deleting..." : "Delete"}
                        </button>
                        <button
                          type="button"
                          style={{ backgroundColor: "#9c2f21" }}
                          className="btn btn-primary"
                          onClick={() => handleSaveAndDelete("save", index)}
                          disabled={isLoading}
                        >
                          {isLoading ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  ))}
                {(daySchedule?.videos?.length == 0 || restday) && (
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      style={{ backgroundColor: "#9c2f21" }}
                      className="btn btn-primary"
                      onClick={handleRestDay}
                      disabled={isLoading}
                    >
                      {isLoading ? "Saving..." : "Save"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default EditWorkoutSchedule;

const fetchDatabyParams = async (
  paramId,
  setLoading,
  setProgram,
  navigation
) => {
  setLoading(true);
  try {
    const response = await getWorkOutProgramByIdApiHandler(paramId);
    const program = response?.program;
    setProgram(program);

    setLoading(false);
  } catch (error) {
    ShowPopUp(navigation);
    setLoading(false);
  }
};

const ShowPopUp = (navigation) => {
  Swal.fire({
    title: "Page not found !",
    text: "Something went wrong please go back!",
    icon: "error",
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Go back!",
  }).then((result) => {
    if (result.isConfirmed) {
      navigation("/workout-programs");
    }
  });
};

export const ADD_RECIPE = "ADD_RECIPE";
export const ADD_RECIPE_SUCCESS = "ADD_RECIPE_SUCCESS";
export const ADD_RECIPE_FAIL = "ADD_RECIPE_FAIL";

export const GET_RECIPES = "GET_RECIPES";
export const GET_RECIPES_SUCCESS = "GET_RECIPES_SUCCESS";
export const GET_RECIPES_FAIL = "GET_RECIPES_FAIL";

export const GET_SINGLE_RECIPES = "GET_SINGLE_RECIPES";
export const GET_SINGLE_RECIPES_SUCCESS = "GET_SINGLE_RECIPES_SUCCESS";
export const GET_SINGLE_RECIPES_FAIL = "GET_SINGLE_RECIPES_FAIL";

export const DELETE_RECIPE = "DELETE_RECIPE";
export const DELETE_RECIPE_SUCCESS = "DELETE_RECIPE_SUCCESS";
export const DELETE_RECIPE_FAIL = "DELETE_RECIPE_FAIL";

export const UPDATE_RECIPE = "UPDATE_RECIPE";
export const UPDATE_RECIPE_SUCCESS = "UPDATE_RECIPE_SUCCESS";
export const UPDATE_RECIPE_FAIL = "UPDATE_RECIPE_FAIL";

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { upDateThumbnailByIdHandler } from "../../apis/workout-video-api";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import classnames from "classnames";
import {
  getWorkOutVideoApiHandler,
  deleteWorkOutVideoByIDApiHandler,
} from "../../apis/workout-video-api/index";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Paginations from "components/Common/Pagination";
import Spinners from "components/Common/Spinner";

const workOutVideos = () => {
  document.title = "mykilo";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [workoutVideo, setWorkOutVideo] = useState([]);

  const [editingCardId, setEditingCardId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);

  const [totalVideos, setTotalVideos] = useState(0);
  const totalPages = Math.ceil(totalVideos / perPageData);

  const handleNavigate = (id) => {
    navigate(`/update-workout-video/${id}`);
  };

  const handleToggleEdit = (id) => {
    if (editingCardId === id) {
      setEditingCardId(null);
    } else {
      setEditingCardId(id);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Set the selected file
  };

  const handleImageUpload = async (id) => {
    setImgLoading(true);
    try {
      const formData = new FormData();
      formData.append("thumbnail", selectedFile);
      const response = await upDateThumbnailByIdHandler(id, formData);
      if (response?.data?.success) {
        fetchData();
      }

      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
    }
  };

  const handleUploadClick = (id) => {
    if (selectedFile != null) {
      handleImageUpload(id);
    }
  };

  const fetchData = async (currentPage) => {
    setLoading(true);
    try {
      const response = await getWorkOutVideoApiHandler(currentPage);
      const data = await response?.data;

      setTotalVideos(data?.totalCounts);
      setWorkOutVideo(data?.videos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  if (loading) {
    return <Spinners />;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Row className="mb-3">
                <Col xl={4} sm={6}>
                  <div className="mt-2">
                    <h5>Workout Videos</h5>
                  </div>
                </Col>
              </Row>

              <Row>
                {workoutVideo && workoutVideo.length > 0 ? (
                  workoutVideo?.map((video, key) => (
                    <Col xl={4} sm={6} key={"_col_" + key}>
                      <div className="card-wrapper">
                        {" "}
                        {/* Added a wrapper div for positioning */}
                        <Card>
                          <CardBody
                            className="p-4"
                            style={{ borderRadius: "10%", height: "600px" }}
                          >
                            <div className="recipe-img position-relative ">
                              {video?.thumbnail && (
                                <>
                                  <img
                                    style={{
                                      height: "330px",
                                      borderRadius: "10px",
                                    }}
                                    src={`https://api.mykilo.co.uk/thumbnail/${video?.thumbnail}`}
                                    alt={video?.thumbnail}
                                    className="img-fluid mx-auto d-block shadow-lg"
                                  />
                                  {editingCardId === video?._id && (
                                    <div
                                      className=""
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        left: "0",
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          textAlign: "center",
                                          color: "#fff",
                                        }}
                                      >
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={handleFileChange}
                                          style={{
                                            display: "block",
                                            marginBottom: "10px",
                                          }}
                                        />

                                        <button
                                          onClick={() =>
                                            handleUploadClick(video?._id)
                                          }
                                          // Pass a function reference
                                          style={{
                                            backgroundColor: "#007bff",
                                            color: "#fff",
                                            border: "none",
                                            padding: "5px 15px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {imgLoading
                                            ? "Uploading..."
                                            : "Upload"}
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                    }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleToggleEdit(video?._id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#9D3022",
                                        border: "none",
                                        padding: "5px 15px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <i
                                        className="bx bx-edit-alt"
                                        style={{ fontSize: "20px" }}
                                      ></i>
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="mt-4 text-center">
                              <h5 className="mb-3 text-truncate font-weight-bold">
                                <Link className="text-dark text-decoration-none">
                                  {video?.video_title}
                                </Link>
                              </h5>
                              <hr
                                className="w-25 mx-auto mb-3"
                                style={{ borderTop: "2px solid #ddd" }}
                              />
                              <p
                                className="text-muted"
                                style={{
                                  overflow: "auto",
                                  maxHeight: "160px",
                                }}
                              >
                                {video?.description}
                              </p>
                            </div>
                          </CardBody>
                          <div
                            className="d-flex justify-content-end gap-4 mb-3 mr-4 "
                            style={{ marginRight: "32px" }}
                          >
                            <Button
                              color="secondary"
                              onClick={() => handleNavigate(video?._id)}
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              Edit
                            </Button>
                            <Button
                              color="danger"
                              className="btn btn-danger waves-effect waves-light"
                              onClick={() =>
                                handleDelete(video?._id, setLoading, fetchData)
                              }
                            >
                              Delete
                            </Button>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h4>No Workout Videos Found</h4>
                )}
              </Row>
              {workoutVideo && workoutVideo?.length > 0 ? (
                <Paginations
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={handlePageChange}
                  paginationDiv="col-lg-12"
                  paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"
                />
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

workOutVideos.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(workOutVideos);

const handleDelete = async (id, setLoading, fetchData) => {
  await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const response = await deleteWorkOutVideoByIDApiHandler(id, setLoading);
      if (response?.success) {
        fetchData();
      }
    }
  });
};

import PropTypes from "prop-types";
import React, { useState } from "react";
// import { login, startLoading, hasError } from "../../redux/Slices/login";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUser, socialLogin } from "../../store/actions";
// import images
import profile from "../../assets/images/profile-img.png";
import backGroundImg from "../../assets/images/login-background.jpg";
import logo from "../../assets/images/my-kilo-logo.png";
import store from "../../store/index";
const Login = (props) => {
  document.title = "Login | My Kilo";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });
  const [successMessage, setSuccessMessage] = useState("");
  const selectLoginState = (state) => state.Login;
  const LoginProperties = createSelector(selectLoginState, (login) => ({
    // error: login.error,
  }));
  const { error } = useSelector(LoginProperties);

  return (
    <React.Fragment>
      <div
        className="account-pages  pt-sm-5"
        style={{
          width: "100vw",
          position: "fixed",
          height: "100vh",
          backgroundSize: "cover",

          backgroundImage: `url(${backGroundImg})`,
        }}
      >
        <Container className="" style={{ marginTop: "10%" }}>
          <Row className="justify-content-center align-items-center h-100  ">
            <Col md={8} lg={6} xl={5} className="h-100  ">
              <Card className="overflow-hidden  ">
                <div className="d-flex justify-content-center align-items-center  bg-black">
                  <img src={logo} alt="my kilo logo" className="w-50 h-50" />
                  {/*
                logo*/}
                </div>
                <CardBody className="pt-0   h-100">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title bg-light"></span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2 ">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <div className="mb-3 mt-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-secondary btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(Login);
Login.propTypes = {
  history: PropTypes.object,
};

{
  /* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>
                          {/*<li className="list-inline-item">*/
}
{
  /*  <TwitterLogin*/
}
{
  /*    loginUrl={*/
}
{
  /*      "http://localhost:4000/api/v1/auth/twitter"*/
}
{
  /*    }*/
}
{
  /*    onSuccess={this.twitterResponse}*/
}
{
  /*    onFailure={this.onFailure}*/
}
{
  /*    requestTokenUrl={*/
}
{
  /*      "http://localhost:4000/api/v1/auth/twitter/revers"*/
}
{
  /*    }*/
}
{
  /*    showIcon={false}*/
}
{
  /*    tag={"div"}*/
}
{
  /*  >*/
}
{
  /*    <a*/
}
{
  /*      href=""*/
}
{
  /*      className="social-list-item bg-info text-white border-info"*/
}
{
  /*    >*/
}
{
  /*      <i className="mdi mdi-twitter"/>*/
}
{
  /*    </a>*/
}
{
  /*  </TwitterLogin>*/
}
{
  /*</li>*/
}
{
  /* <li className="list-inline-item">
                          <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={e => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div> */
}
{
  /* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */
}
{
  /* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */
}

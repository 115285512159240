import React, { useState } from "react";

const PageContent = ({ data }) => {
  const [selectedHeading, setSelectedHeading] = useState(null);

  const scrollToContent = (contentId) => {
    const element = document.getElementById(contentId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setSelectedHeading(contentId);
  };

  return (
    <>
      <div className="container my-2">
        <div className="row">
          <aside className="col">
            <div className="p-2 sticky-top">
              {/* Map through your data to render headings */}
              {data?.map(({ id, heading }) => (
                <h2
                  key={id}
                  className={`cursor-pointer text-dark py-1 ${
                    selectedHeading === id
                      ? "font-weight-bold"
                      : "font-weight-normal"
                  }`}
                  onClick={() => scrollToContent(id)}
                >
                  {heading}
                </h2>
              ))}
            </div>
          </aside>

          <div className="col-md-8 p-2">
            {/* Map through your data to render content */}
            {data.map(({ id, content, heading }) => (
              <div key={id} id={id}>
                <h2 className="h2 font-weight-semibold pb-2 text-dark">
                  {heading}
                </h2>
                <p className="text-normal py-1 leading-normal text-dark">
                  {content}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageContent;

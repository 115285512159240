import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "reactstrap";
import { ToastContainer } from "react-toastify";
import Spinners from "components/Common/Spinner";
import loading from "../../../assets/images/loading.gif";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  getWorkOutProgramByIdApiHandler,
  updateWorkOutProgramAPiHadler,
} from "../../../apis/workout-program-api";
import {
  programCategoriesConstant,
  focusTypeConstant,
  equipmentTypeEnum,
  programYearTypeEnum,
  programDuritionEnum,
} from "../../../utils/workout-program-constant";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import * as Yup from "yup";
import { useFormik } from "formik";

const UpdateWorkOutProgram = ({ heading1, title }) => {
  //meta title
  document.title = "mykilo";
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
    }),
  };

  const navigation = useNavigate();
  const { id: parmsId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(false);
  const [durationValue, setDurationValue] = useState(null);

  const [initialValues, setInitialValues] = useState({
    program_title: "",
    program_description: "",
    program_categories: [],
    focus_type: [],
    program_year: [],
    program_duration: "",

    equipment: [],
    program_time: "",
  });

  useEffect(() => {
    setGetDataLoading(true);
    const fetchData = async () => {
      const response = await getWorkOutProgramByIdApiHandler(
        parmsId,
        setGetDataLoading
      );
      if (response) {
        setInitialValues({
          program_title: response?.program?.program_title,
          program_description: response?.program?.program_description,
          program_categories: response?.program?.program_categories?.map(
            (option, index) => {
              const isString = typeof option === "string";
              return {
                value: option,
                label: isString ? option.replace(/_/g, " ") : "",
              };
            }
          ),
          focus_type: response?.program?.focus_type?.map((option, index) => {
            const isString = typeof option === "string";
            return {
              value: option,
              label: isString ? option.replace(/_/g, " ") : "",
            };
          }),
          program_year: response?.program?.program_year?.map(
            (option, index) => {
              return {
                value: option,
                label: option,
              };
            }
          ),
          program_duration: response?.program?.program_duration,

          equipment: response?.program?.equipment?.map((option, index) => {
            const isString = typeof option === "string";
            return {
              value: option,
              label: isString ? option.replace(/_/g, " ") : "",
            };
          }),
          program_time: response?.program?.program_time,
        });
        const durationObject = {
          label: response?.program?.program_duration,
          value: response?.program?.program_duration,
        };
        setDurationValue(durationObject);

        setGetDataLoading(false);
      }
    };
    fetchData();
  }, []);

  function getArrayOfValues(array) {
    return array.map((item) => item.value);
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
      program_title: Yup.string().required(" Program Title is required"),
      program_description: Yup.string().required(
        "Program description is required"
      ),
      program_categories: Yup.array().min(
        1,
        "Select at least one Program categories"
      ),
      focus_type: Yup.array().min(1, "Select at least one Focus type"),
      equipment: Yup.array().min(1, "Select at least one equipment"),
      program_year: Yup.array().min(1, "Select at least one Program Year"),
      program_time: Yup.string().required(" Program Time is required"),

      program_duration: Yup.string().required(" Program Duration is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = {
        program_title: values.program_title,
        program_description: values.program_description,
        program_categories: getArrayOfValues(values.program_categories),
        focus_type: getArrayOfValues(values.focus_type),
        equipment: getArrayOfValues(values.equipment),
        program_duration: values.program_description,
        program_year: getArrayOfValues(values.program_year),
        program_time: values.program_time,
      };

      const response = await updateWorkOutProgramAPiHadler(
        data,
        setIsLoading,
        parmsId
      );
      if (response?.success) {
        navigation("/workout-programs");
      }
    },
  });

  if (getDataLoading) {
    return <Spinners />;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem={title} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{heading1}</CardTitle>
                  <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="program_title"
                        className="col-form-label col-lg-2"
                      >
                        Program Tilte
                      </Label>
                      <Col lg="10">
                        <Input
                          id="program_title"
                          name="program_title"
                          type="text"
                          className="form-control"
                          placeholder="Enter Program Title"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.program_title || ""}
                        />
                        {validation.touched.program_title &&
                        validation.errors.program_title ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.program_title}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="program_description"
                        className="col-form-label col-lg-2"
                      >
                        Program Description
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="program_description"
                          rows="3"
                          name="program_description"
                          placeholder="Enter program description..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.program_description || ""}
                        />
                        {validation.touched.program_description &&
                        validation.errors.program_description ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.program_description}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Program Categories
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="program_categories"
                          name="program_categories"
                          isMulti
                          value={validation?.values?.program_categories}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "program_categories",
                              selectedOptions
                            );
                            validation.handleBlur("program_categories");
                          }}
                          options={programCategoriesConstant?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.program_categories &&
                          validation.errors.program_categories && (
                            <div className="text-danger">
                              {validation.errors.program_categories}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Focus Type{" "}
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="focus_type"
                          name="focus_type"
                          isMulti
                          value={validation?.values?.focus_type || ""}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            const updatedFocusTypes = [
                              ...validation.values.focus_type,
                              selectedOptions.value,
                            ];

                            validation.setFieldValue(
                              "focus_type",
                              selectedOptions
                            );
                            validation.handleBlur("focus_type");
                          }}
                          options={focusTypeConstant?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.focus_type &&
                          validation.errors.focus_type && (
                            <div className="text-danger">
                              {validation.errors.focus_type}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Equipment
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="equipment"
                          name="equipment"
                          isMulti
                          value={validation?.values?.equipment}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "equipment",
                              selectedOptions
                            );
                            validation.handleBlur("equipment");
                          }}
                          options={equipmentTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.equipment &&
                          validation.errors.equipment && (
                            <div className="text-danger">
                              {validation.errors.equipment}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Program Duration
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="program_duration"
                          name="program_duration"
                          value={durationValue}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            setDurationValue(selectedOptions);
                            validation.setFieldValue(
                              "program_duration",
                              selectedOptions?.value
                            );
                            validation.handleBlur("program_duration");
                          }}
                          options={programDuritionEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, "-"),
                          }))}
                        />
                        {validation.touched.program_duration &&
                          validation.errors.program_duration && (
                            <div className="text-danger">
                              {validation.errors.program_duration}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Program Year
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="program_year"
                          name="program_year"
                          isMulti
                          value={validation?.values?.program_year || ""}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "program_year",
                              selectedOptions
                            );
                            validation.handleBlur("program_year");
                          }}
                          options={programYearTypeEnum?.map((option) => ({
                            value: option,
                            label: option,
                          }))}
                        />
                        {validation.touched.program_year &&
                          validation.errors.program_year && (
                            <div className="text-danger">
                              {validation.errors.program_year}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="program_title"
                        className="col-form-label col-lg-2"
                      >
                        Program Time
                      </Label>
                      <Col lg="10">
                        <Input
                          id="program_time"
                          name="program_time"
                          type="text"
                          className="form-control"
                          placeholder="Enter Program Time"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.program_time || ""}
                        />
                        {validation.touched.program_time &&
                        validation.errors.program_time ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.program_time}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <div className=" d-flex  justify-content-end  ">
                      <Button type="submit" className="" disabled={isLoading}>
                        {isLoading ? "Loading..." : "submit"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UpdateWorkOutProgram;

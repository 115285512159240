import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const CouponLimitExpiry = () => {
  const [couponLimit, setCouponLimit] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const handleSave = () => {
    // Handle saving coupon limit and expiry date
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row className="justify-content-center mt-5">
          <Col md="6">
            <Card>
              <CardBody>
                <h2 className="text-center mb-4">Coupon Management</h2>
                <Form>
                  <FormGroup>
                    <Label for="couponLimit">Coupon Limit:</Label>
                    <Input
                      type="number"
                      id="couponLimit"
                      value={couponLimit}
                      onChange={(e) => setCouponLimit(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="expiryDate">Expiry Date:</Label>
                    <Input
                      type="date"
                      id="expiryDate"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                    />
                  </FormGroup>
                  <div className="text-center">
                    <Button color="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CouponLimitExpiry;

import axios, { axiosWithoutToken } from "../index";

import "react-toastify/dist/ReactToastify.css";
import { successToster, errorToster } from "../api-response-toaster";
export const uploadWorkOutProgramAPiHadler = async (formData, setIsLoading) => {
  try {
    const response = await axios.post("admin/upload/program", formData);

    const data = await response?.data;
    successToster(data?.message, "top-right");

    setIsLoading(false);
    return data;
  } catch (error) {
    setIsLoading(false);
  }
};

export const getWorkOutProgramsApiHandler = async (page = 1) => {
  try {
    const response = await axios.get(`/admin/programs/list?page=${page}`);
    const data = response?.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const getWorkOutProgramByIdApiHandler = async (
  id,
  setGetDataLoading
) => {
  try {
    const response = await axios.get(`/admin/getUniqueProgram/${id}`);
    const data = response?.data;
    return data;
  } catch (error) {
    setGetDataLoading(false);
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const uploadDaysWorkOurProgram = async (
  id,
  formData,
  setRestDay,
  setInputs,
  setOptional,
  setSubmitLoading
) => {
  try {
    const response = await axios.put(`admin/update/program/${id}`, formData);
    const data = await response?.data;
    successToster(data?.message, "top-right");
    setRestDay(false);
    setInputs({ title: "", sub_title: "", video: undefined });
    setOptional(false);

    setSubmitLoading(false);
    return data;
  } catch (error) {
    errorToster(error?.response?.data?.message, "top-right");

    setRestDay(false);
    setInputs({ title: "", sub_title: "", video: undefined });
    setOptional(false);
    setSubmitLoading(false);
  }
};

export const updateWorkOutProgramAPiHadler = async (
  formData,
  setIsLoading,
  id
) => {
  try {
    const response = await axios.put(
      `admin/updateWorkoutProgram/${id}`,
      formData
    );
    const data = await response?.data;
    successToster(data?.message, "top-right");
    setIsLoading(false);
    return data;
  } catch (error) {
    errorToster(error?.response?.data?.message, "top-right");

    setIsLoading(false);
  }
};

export const deleteWorkOutProgramByIDApiHandler = async (
  programID,
  setLoading
) => {
  try {
    const response = await axios.delete("admin/deleteWorkoutProgram    ", {
      data: {
        programId: programID,
      },
    });
    const data = await response?.data;

    successToster(data?.message, "top-right");

    setLoading(false);
    return data;
  } catch (error) {
    errorToster(error?.response?.data?.error, "top-right");
    setLoading(false);
  }
};

// /admin/updateWorkoutProgramShedule/{programId}

export const updateScheduleApiHandler = async (formData, id, setIsLoading) => {
  try {
    const response = await axios.put(
      `/admin/updateWorkoutProgramShedule/${id}`,
      formData
    );
    const data = await response?.data;
    successToster(data?.message, "top-right");
    setIsLoading(false);
    return data;
  } catch (error) {
    errorToster(error?.response?.data?.message, "top-right");
    setIsLoading(false);
    console.log("error", error);
  }
};

export const deleteScheduleApiHandler = async (
  videoId,
  id,
  setDeleteLoading
) => {
  try {
    const response = await axios.delete(
      `admin/deleteWorkoutProgramSchedule/${id}`,
      {
        data: {
          videoId: videoId,
        },
      }
    );
    const data = await response?.data;
    successToster(data?.message, "top-right");
    setDeleteLoading(false);
    return data;
  } catch (error) {
    errorToster(error?.response?.data?.message, "top-right");
    setDeleteLoading(false);
  }
}; 

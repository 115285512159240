import {
  ADD_RECIPE,
  ADD_RECIPE_SUCCESS,
  ADD_RECIPE_FAIL,
  GET_RECIPES,
  GET_RECIPES_SUCCESS,
  GET_RECIPES_FAIL,
  DELETE_RECIPE,
  DELETE_RECIPE_SUCCESS,
  DELETE_RECIPE_FAIL,
  UPDATE_RECIPE,
  UPDATE_RECIPE_SUCCESS,
  UPDATE_RECIPE_FAIL,
  GET_SINGLE_RECIPES,
  GET_SINGLE_RECIPES_SUCCESS,
  GET_SINGLE_RECIPES_FAIL,
} from "../../store/Recipe/actionTypes";

// Add Recipe
export const addRecipe = (recipe) => ({
  type: ADD_RECIPE,
  payload: recipe,
});

export const addRecipeSuccess = (recipe) => ({
  type: ADD_RECIPE_SUCCESS,
  payload: recipe,
});

export const addRecipeFail = (error) => ({
  type: ADD_RECIPE_FAIL,
  payload: error,
});

// Get Recipes
export const getRecipes = (limit = 10, page = 1) => ({
  type: GET_RECIPES,
  payload: { limit, page },

});

export const getRecipesSuccess = (recipes) => {
  const action = {
    type: GET_RECIPES_SUCCESS,
    payload: recipes,
  };

  
  return action;
};


export const getRecipesFail = (error) => ({
  type: GET_RECIPES_FAIL,
  payload: error,
});

// Delete Recipe
export const deleteRecipe = (recipeId) => ({
  type: DELETE_RECIPE,
  payload: recipeId,
});

export const deleteRecipeSuccess = (recipeId) => ({
  type: DELETE_RECIPE_SUCCESS,
  payload: recipeId,
});

export const deleteRecipeFail = (error) => ({
  type: DELETE_RECIPE_FAIL,
  payload: error,
});

export const updateRecipe = (recipe) => ({
  type: UPDATE_RECIPE,
  payload: recipe,
});

export const updateRecipeSuccess = (recipe) => ({
  type: UPDATE_RECIPE_SUCCESS,
  payload: recipe,
});

export const updateRecipeFail = (error) => ({
  type: UPDATE_RECIPE_FAIL,
  payload: error,
});

export const getSingleRecipes = (recipeId) => ({
  type: GET_SINGLE_RECIPES,
  payload: recipeId,


});

export const getSingleRecipesSuccess = (recipes) => {
  const action = {
    type: GET_SINGLE_RECIPES_SUCCESS,
    payload: recipes,
  };

  
  return action;
};


export const getSingleRecipesFail = (error) => ({
  type: GET_SINGLE_RECIPES_FAIL,
  payload: error,
});

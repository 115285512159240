import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getWorkOutProgramByIdApiHandler,
  uploadDaysWorkOurProgram,
} from "../../../apis/workout-program-api";

import Spinners from "../../../components/Common/Spinner";
import Swal from "sweetalert2";
import {
  days_1_to_14,
  days_15_to_21,
  days_22_to_28,
} from "../../../utils/workout-program-constant";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  CardSubtitle,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
const AddWorkoutSchedule = ({ title }) => {
  const { id: paramId } = useParams();
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [program, setProgram] = useState(null);
  const [restday, setRestDay] = useState(false);
  const [days, setDays] = useState([]);
  const [optional, setOptional] = useState(false);

  const [selectedDay, setSelectedDay] = useState(1);
  const [inputs, setInputs] = useState({
    title: "",
    sub_title: "",
    video: undefined,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value, type, checked, files } = event.target;
    const newInputs = { ...inputs };
    if (type === "file") {
      newInputs[name] = files[0];
    } else {
      newInputs[name] = value;
    }

    setInputs(newInputs);
    setErrors({ ...errors, [name]: null });
  };
  const handleClick = (event) => {
    event.target.value = null;
  };
  useEffect(() => {
    fetchDatabyParams(paramId, setLoading, setProgram, navigation);
  }, [paramId]);

  useEffect(() => {
    setDays(
      program?.program_duration == "1_14_DAYS"
        ? days_1_to_14
        : program?.program_duration == "15_21_DAYS"
        ? days_15_to_21
        : program?.program_duration == "22_28_DAYS"
        ? days_22_to_28
        : []
    );
  }, [program]);
  const validateInputs = () => {
    let newErrors = {};

    if (!inputs.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!inputs.sub_title.trim()) {
      newErrors.sub_title = "Sub title is required";
    }
    if (!inputs.video) {
      newErrors.video = "Video is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  const handleSave = async () => {
    if (restday) {
      setSubmitLoading(true);
      const formData = new FormData();
      formData.append("restday", restday);
      formData.append("day_number", selectedDay);

      const response = await uploadDaysWorkOurProgram(
        paramId,
        formData,
        setRestDay,
        setInputs,
        setOptional,
        setSubmitLoading
      );
    } else {
      if (validateInputs()) {
        setSubmitLoading(true);
        const data = {
          ...inputs,
          optional: optional,
          day_number: selectedDay,
        };
        const formData = new FormData();

        for (const key in data) {
          if (key === "video" && data[key]) {
            formData.append(key, data[key], data[key].name);
          } else {
            formData.append(key, data[key]);
          }
        }
        const responnse = await uploadDaysWorkOurProgram(
          paramId,
          formData,
          setRestDay,
          setInputs,
          setOptional,
          setSubmitLoading
        );
      }
    }
  };

  if (loading) {
    return <Spinners />;
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Projects" breadcrumbItem={title} />
        <Card className="p-4">
          <CardTitle tag="h5">Tilte : {program?.program_title}</CardTitle>
          <CardSubtitle className="mb-2 mt-2 text-muted" tag="h6">
            Description : {program?.program_description}
          </CardSubtitle>
        </Card>
        <div className="d-flex justify-content-between">
          <div className="" style={{ width: "30%" }}>
            <CardTitle tag="h3">Select Day</CardTitle>
            <div
              className="d-flex flex-wrap p-2 rounded bg-white"
              style={{ border: "1px solid #9a2925" }}
            >
              {days?.map((day, index) => (
                <p
                  className="rounded p-2 m-2"
                  key={index}
                  style={{
                    border:
                      day === selectedDay
                        ? "2px solid #9a2925"
                        : "1px solid black",
                    cursor: "pointer",
                    backgroundColor:
                      day === selectedDay ? "#9c2f21" : "transparent",
                    color: day === selectedDay ? "#FFFFFF" : "#000000",
                  }}
                  onClick={() => setSelectedDay(day)}
                >
                  Day {day}
                </p>
              ))}
            </div>
          </div>
          <div className=" " style={{ width: "68%" }}>
            <CardTitle tag="h3">Day {selectedDay}</CardTitle>{" "}
            <div
              className="rounded p-2"
              style={{ border: "1px solid #9a2925" }}
            >
              <div className="form-check mr-1 ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={restday}
                  onClick={() => {
                    setRestDay(!restday);
                  }}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Mark as rest day
                </label>
              </div>
              {restday === false && (
                <div className="container mt-3 mb-b">
                  <div className="d-flex justify-content-between">
                    <div className=" " style={{ width: "40%" }}>
                      <input
                        className="p-2 rounded w-100"
                        name="title"
                        placeholder="Enter Title"
                        type="text"
                        value={inputs.title}
                        onChange={(event) => handleChange(event)}
                      />
                      {errors.title && (
                        <p className="text-danger">{errors.title}</p>
                      )}
                    </div>

                    <div className=" " style={{ width: "40%" }}>
                      <input
                        className="p-2 rounded w-100"
                        name="sub_title"
                        placeholder="Enter Sub Title"
                        type="text"
                        value={inputs.sub_title}
                        onChange={(event) => handleChange(event)}
                      />
                      {errors.sub_title && (
                        <p className="text-danger">{errors.sub_title}</p>
                      )}
                    </div>
                  </div>

                  <div
                    className="video-upload-container rounded bg-white"
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      height: "350px",
                      border: "1px solid black",
                      position: "relative",
                    }}
                  >
                    <label
                      htmlFor="videoInput"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                      }}
                    >
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                      <h5 className="ml-3">Add your video</h5>
                    </label>
                    <input
                      id="videoInput"
                      name="video"
                      type="file"
                      accept="video/*"
                      onChange={(event) => handleChange(event)}
                      onClick={handleClick}
                      style={{ display: "none" }}
                    />
                    {inputs.video && (
                      <div
                        style={{
                          position: "relative",
                          zIndex: 1,
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <video
                          width="100%"
                          height="100%"
                          controls
                          style={{ position: "absolute", top: 0, left: 0 }}
                        >
                          <source
                            src={URL.createObjectURL(inputs.video)}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                  </div>
                  {errors.video && (
                    <p className="text-danger">{errors.video}</p>
                  )}

                  <div className="form-check mr-1 ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={optional}
                      onClick={() => {
                        setOptional(!optional);
                      }}
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Mark as Optional
                    </label>
                  </div>
                </div>
              )}

              <div className="d-flex  justify-content-end mt-3 mb-3">
                <button
                  type="button"
                  style={{ backgroundColor: "#9c2f21" }}
                  className="btn btn-primary"
                  onClick={() => handleSave()}
                  disabled={submitLoading}
                >
                  {submitLoading ? "Saving..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AddWorkoutSchedule;

const fetchDatabyParams = async (
  paramId,
  setLoading,
  setProgram,
  navigation
) => {
  setLoading(true);
  try {
    const response = await getWorkOutProgramByIdApiHandler(paramId);
    const program = response?.program;
    setProgram(program);

    setLoading(false);
  } catch (error) {
    ShowPopUp(navigation);
    setLoading(false);
  }
};

const ShowPopUp = (navigation) => {
  Swal.fire({
    title: "Page not found !",
    text: "Something went wrong please go back!",
    icon: "error",
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Go back!",
  }).then((result) => {
    if (result.isConfirmed) {
      navigation("/workout-programs");
    }
  });
};

import {
  ADD_RECIPE_SUCCESS,
  ADD_RECIPE_FAIL,
  GET_RECIPES_SUCCESS,
  GET_SINGLE_RECIPES_SUCCESS,
  GET_RECIPES_FAIL,
  DELETE_RECIPE_SUCCESS,
  DELETE_RECIPE_FAIL,
  UPDATE_RECIPE_SUCCESS,
  UPDATE_RECIPE_FAIL,
  // ... other imports
} from "../../store/Recipe/actionTypes";

const INIT_STATE = {
  recipes: [],
  error: null,
  loading: true,
  // ... other state properties
};

const Recipe = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case ADD_RECIPE_SUCCESS:
    //   return {
    //     ...state,
    //     recipes: [...state.recipes, action.payload],
    //     loading: false,
    //     error: null,
    //   };

    // case ADD_RECIPE_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    case GET_RECIPES_SUCCESS:
      return {
        ...state,
        recipes: action.payload,
        loading: false,
        error: null,
      };

    // case GET_RECIPES_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    case DELETE_RECIPE_SUCCESS:
      return {
        ...state,
        recipes: state?.recipes?.recipes?.filter(
          (recipe) => recipe?._id !== action.payload
        ),
        loading: false,
        error: null,
      };

    // case DELETE_RECIPE_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    // ... other cases
    // case UPDATE_RECIPE_SUCCESS:
    //   const updatedRecipes = state.recipes.map((recipe) =>
    //     recipe.id === action.payload.id ? action.payload : recipe
    //   );
    //   return {
    //     ...state,
    //     recipes: updatedRecipes,
    //     loading: false,
    //     error: null,
    //   };

    // case UPDATE_RECIPE_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };
    case GET_SINGLE_RECIPES_SUCCESS:
      return {
        ...state,
        recipes: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default Recipe;

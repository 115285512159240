export const CourseOptions = [
  "Budget Meals",
  "Easy Breakfast Ideas",
  "Healthy Desert Recipes",
  "Quick And Easy Recipes",
  "Healthy Snack Ideas",
  "Healthy Drinks Recipes",
  "Easy Vegan Recipes",
  "Healthy Pancakes Recipes",
  "Party Food Recipes",
  "Appetizers",
  "Breakfast",
  "Desserts",
  "Drinks",
  "Mains",
  "Salads",
  "Side Dish",
  "Snacks",
];

// ["Dairy Free", "Gluten Free", "Pescatarian", "Vegan", "Vegetarian"],
export const displayEducationOptions = {
  [" Dairy Free"]: "DairyFree",
  ["Gluten Free"]: "Gluten Free",
  Pescatarian: "Pescatarian",
  Vegan: "Vegan",
  Vegetarian: "Vegetarian",
};
export const educationOptions = [
  "Dairy_Free",
  "Gluten_Free",
  "Pescatarian",
  "Vegan",
  "Vegetarian",
];
export const recipeCategories = [

  "budget_meals",
  "easy_breakfast_ideas",
  "healthy_desert_recipes",
  "quick_and_Easy_recipes",
  "healthy_snack_ideas",
  "healthy_drinks_recipes",
  "easy_vegan_recipes",
  "healthy_pancakes_recipes",
  "party_food_recipes",
];

export const preferenceOptions = ["High Protein ", "Low Carb", "Low Fat"];
export const convenienceOptons = ["Baking", "Meal_Perp", "No_Cook", "One_Pan"];

export const videoTypeEnum = [
  "ABS",
  "ARMS",
  "BACK",
  "BOOTY",
  "CHEST",
  "FULL_BODY",
  "LEGS",
  "LOWER_BODY",
  "UPPER_BODY",
];
export const preferenceEnum = [
  "LOW_IMPACT_ALTERNATIVES",
  "NO_BURPEES",
  "NO_JUMPING",
  "NO_PLANKS",
  "REPS_BASED",
  "STANDING_WORKOUT",
  "WRIST_FRIENDLY",
];
export const WorkoutTypeEnum = [
  "BODY_WEIGHT_WORKOUTS",
  "COOLDOWN",
  "HIIT_CARDIO",
  "WEIGHTTED_WORKOUTS",
  "WARM_UP",
];
export const equipmentTypeEnum = [
  "BENCH",
  "DUMBBELLS",
  "RESISTANCE_BANDS",
  "KILO_BUSTER",
  "KILO_BUBBLE",
];
export const historyTypeEnum = ["I've_Completed", "I've_Not_Completed"];
export const durationTypeEnum = [
  "5_10_MIN",
  "10_15_MIN",
  "15_20_MIN",
  "20_MIN+",
];
export const VideoCategoriesEnum = [
  "HIIT",
  "Abs",
  "Booty",
  "Dumbbell",
  "10_Mins",
  "20_Mins+",
  "Standing_Workouts",
  "No_Jumping",
  "No_Planks",
  "Burpee_Free",
  "Wrist_Friendly",
  "Yoga",
  "Pilates",
];

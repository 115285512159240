import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "reactstrap";
import { ToastContainer } from "react-toastify";
import loading from "../../../assets/images/loading.gif";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { uploadWorkOutProgramAPiHadler } from "../../../apis/workout-program-api"; // add program api
import {
  programCategoriesConstant,
  focusTypeConstant,
  equipmentTypeEnum,
  programYearTypeEnum,
  programDuritionEnum,
} from "../../../utils/workout-program-constant";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import * as Yup from "yup";
import { useFormik } from "formik";

const UploadWorkOutProgram = ({ heading1, title }) => {
  //meta title
  document.title = "mykilo";
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
    }),
  };

  const navigation = useNavigate();

  const [coverImage, setCoverImage] = useState(null);
  const [durationValue, setDurationValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    program_title: "",
    program_description: "",
    program_categories: [],
    focus_type: [],
    program_year: [],
    program_duration: "",
    program_image: null,
    equipment: [],
    program_time: "",
  });

  function appendArrayWithIndex(formData, array, keyName) {
    array.forEach((item, index) => {
      formData.append(`${keyName}[${index}]`, item.value);
    });
  }

  const handleCoverImageChange = (files) => {
    const file = files[0];
    setCoverImage(file);
    validation.setFieldValue("program_image", file);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: initialValues,
    validationSchema: Yup.object({
      program_title: Yup.string().required(" Program Title is required"),
      program_description: Yup.string().required(
        "Program description is required"
      ),
      program_categories: Yup.array().min(
        1,
        "Select at least one Program Categories"
      ),
      focus_type: Yup.array().min(1, "Select at least one Focus type"),
      equipment: Yup.array().min(1, "Select at least one equipment"),
      program_year: Yup.array().min(1, "Select at least one Program Year"),
      program_time: Yup.number().required(" Program Time is required"),
      program_image: Yup.mixed().required("Thumbnail is required"),
      program_duration: Yup.string().required("Program Duration is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("program_title", values.program_title);
      formData.append("program_description", values.program_description);

      formData.append("workOutProgram", values.program_image);

      formData.append("program_time", values.program_time);
      formData.append("program_duration", values.program_duration);

      appendArrayWithIndex(
        formData,
        values.program_categories,
        "program_categories"
      );
      appendArrayWithIndex(formData, values.focus_type, "focus_type");
      appendArrayWithIndex(formData, values.equipment, "equipment");
      appendArrayWithIndex(formData, values.program_year, "program_year");

      const response = await uploadWorkOutProgramAPiHadler(
        formData,
        setIsLoading
      );
      if (response?.success) {
        navigation("/workout-programs");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem={title} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{heading1}</CardTitle>
                  <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="program_title"
                        className="col-form-label col-lg-2"
                      >
                        Program Tilte
                      </Label>
                      <Col lg="10">
                        <Input
                          id="program_title"
                          name="program_title"
                          type="text"
                          className="form-control"
                          placeholder="Enter Program Title"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.program_title || ""}
                        />
                        {validation.touched.program_title &&
                        validation.errors.program_title ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.program_title}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="program_description"
                        className="col-form-label col-lg-2"
                      >
                        Program Description
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="program_description"
                          rows="3"
                          name="program_description"
                          placeholder="Enter program description..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.program_description || ""}
                        />
                        {validation.touched.program_description &&
                        validation.errors.program_description ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.program_description}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Program Categories
                      </Label>
                      <Col lg={10}>
                        <Select
                          isMulti
                          id="program_categories"
                          name="program_categories"
                          value={validation?.values?.program_categories || ""}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "program_categories",
                              selectedOptions
                            );
                            validation.handleBlur("program_categories");
                          }}
                          options={programCategoriesConstant?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.program_categories &&
                          validation.errors.program_categories && (
                            <div className="text-danger">
                              {validation.errors.program_categories}
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Focus Type{" "}
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="focus_type"
                          name="focus_type"
                          isMulti
                          value={validation?.values?.focus_type || ""}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            const updatedFocusTypes = [
                              ...validation.values.focus_type,
                              selectedOptions.value,
                            ];

                            validation.setFieldValue(
                              "focus_type",
                              selectedOptions
                            );
                            validation.handleBlur("focus_type");
                          }}
                          options={focusTypeConstant?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.focus_type &&
                          validation.errors.focus_type && (
                            <div className="text-danger">
                              {validation.errors.focus_type}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Equipment
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="equipment"
                          name="equipment"
                          isMulti
                          value={validation?.values?.equipment}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "equipment",
                              selectedOptions
                            );
                            validation.handleBlur("equipment");
                          }}
                          options={equipmentTypeEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, " "),
                          }))}
                        />
                        {validation.touched.equipment &&
                          validation.errors.equipment && (
                            <div className="text-danger">
                              {validation.errors.equipment}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Program Duration
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="program_duration"
                          name="program_duration"
                          value={durationValue}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            setDurationValue(selectedOptions);
                            validation.setFieldValue(
                              "program_duration",
                              selectedOptions?.value
                            );
                            validation.handleBlur("program_duration");
                          }}
                          options={programDuritionEnum?.map((option) => ({
                            value: option,
                            label: option.replace(/_/g, "-"),
                          }))}
                        />
                        {validation.touched.program_duration &&
                          validation.errors.program_duration && (
                            <div className="text-danger">
                              {validation.errors.program_duration}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-form-label col-sm-2">
                        Program Year
                      </Label>
                      <Col lg={10}>
                        <Select
                          id="program_year"
                          name="program_year"
                          isMulti
                          value={validation?.values?.program_year || ""}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            validation.setFieldValue(
                              "program_year",
                              selectedOptions
                            );
                            validation.handleBlur("program_year");
                          }}
                          options={programYearTypeEnum?.map((option) => ({
                            value: option,
                            label: option,
                          }))}
                        />
                        {validation.touched.program_year &&
                          validation.errors.program_year && (
                            <div className="text-danger">
                              {validation.errors.program_year}
                            </div>
                          )}
                      </Col>
                    </Row>

                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="program_title"
                        className="col-form-label col-lg-2"
                      >
                        Program Time
                      </Label>
                      <Col lg="10">
                        <Input
                          id="program_time"
                          name="program_time"
                          type="text"
                          className="form-control"
                          placeholder="Enter Program Time"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.program_time || ""}
                        />
                        {validation.touched.program_time &&
                        validation.errors.program_time ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.program_time}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </FormGroup>

                    <Row>
                      <FormGroup className="mb-4" row>
                        <Label
                          htmlFor="program_image"
                          className="col-form-label col-lg-2"
                        >
                          Thumbnail Image
                        </Label>
                        <Col lg="10">
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleCoverImageChange(acceptedFiles)
                            }
                            accept="image/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div>
                                <div
                                  className="dropzone d-flex justify-content-center align-items-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  {!coverImage && (
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      <h4>
                                        Drop files here or click to upload Size
                                        must be 1980 x 1320
                                      </h4>
                                    </div>
                                  )}
                                  {coverImage && (
                                    <div className="image-preview-box mt-2 position-relative">
                                      <img
                                        src={URL?.createObjectURL(coverImage)}
                                        alt="Cover Image Preview"
                                        style={{
                                          maxHeight: "220px",
                                          maxWidth: "220px",
                                        }}
                                      />
                                      {/* Add delete button */}
                                      {coverImage && (
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm position-absolute top-0 end-0"
                                          style={{ zIndex: 1 }} // Ensure the button is above the image
                                          onClick={() => setCoverImage(null)} // This will delete the cover image
                                        >
                                          X
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="mt-2 d-flex justify-content-center align-items-center">
                                  <button
                                    type="button"
                                    style={{ backgroundColor: "#9c2f21" }}
                                    className="btn btn-primary"
                                    onClick={() => {
                                      // Trigger the file input click when the button is pressed
                                      document
                                        .querySelector('input[type="file"]')
                                        .click();
                                    }}
                                  >
                                    Upload Image
                                  </button>
                                </div>
                                {validation.touched.program_image &&
                                validation.errors.program_image ? (
                                  <FormFeedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation.errors.program_image}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            )}
                          </Dropzone>
                          {validation.touched.coverImage &&
                          validation.errors.coverImage ? (
                            <div className="text-danger mt-2">
                              {validation.errors.coverImage}
                            </div>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </Row>

                    <div className=" d-flex  justify-content-end  ">
                      <Button type="submit" className="" disabled={isLoading}>
                        {isLoading ? "Loading..." : "submit"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default UploadWorkOutProgram;

const convertCoverImageToBinaryIfRequired = async (thumbnail) => {
  if (thumbnail instanceof File && thumbnail.type.startsWith("image/")) {
    // If cover image is not already in binary format, convert it
    if (
      !thumbnail.type.includes("image/jpeg") &&
      !thumbnail.type.includes("image/png")
    ) {
      convertImageToBinary(thumbnail);
    }
  }
  return thumbnail;
};

// Function to convert image to binary format
const convertImageToBinary = async (image) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(image);
  });
};

export const programCategoriesConstant = [
  "Beginner_Friendly",
  "Moderate_To_Advanced",
  "Weight_Loss",
  "Abs",
  "Booty_and_Legs",
  "Strength_Training",
  "No_Equipment",
];
export const focusTypeConstant = [
  "ARMS",
  "BOOTY",
  "FULL_BODY",
  "LEGS",
  "ABS&CORE",
  "RESISTANCE",
  "WEIGHT_LOSS",
];

export const equipmentTypeEnum = [
  "DUMBBELLS",
  "FIRNESS_MAT",
  "RESISTANCE_BANDS",
  "KILO_BUSTER",
  "KILO_BUBBLE"
];

export const historyTypeEnum = ["I've_Tried", "I've_Not_Tried"];
export const programYearTypeEnum = [2024, 2025, 2026, 2027, 2028, 2029, 2030];
export const programDuritionEnum = [
  "1_14_DAYS",
  "15_21_DAYS",
  "22_28_DAYS",
  "29_DAYS+",
];

export const days_1_to_14 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export const days_15_to_21 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
];
export const days_22_to_28 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28,
];
